import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy: React.FC = () => {
    return (
        <div className="min-h-screen min-w-full flex flex-col justify-center items-center bg-gray-50">
            <div className="w-full max-w-md flex flex-col justify-center items-center px-4 sm:px-6 lg:px-8">
                <Link to="/">
                    <img
                        className="mx-auto h-10 sm:h-14 w-auto select-none"
                        src="/TimeMaps.webp"
                        alt="TimeMaps"
                        draggable="false"
                    />
                </Link>
                <span className="justify-center text-center select-none mt-2 tracking-widest">Time Maps</span>
                <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                    Privacy Policy
                </h2>
                <p className="mt-4 mb-10 text-center text-base leading-7 text-slate-600">
                    Last updated on November 2, 2021
                </p>
            </div>


            <div className="relative px-4 sm:px-6 lg:px-8">
                <div
                    className="mx-auto max-w-[40rem] prose-sm prose prose-slate prose-a:font-semibold prose-a:text-sky-500 hover:prose-a:text-sky-600">
                    <p>
                        This privacy policy ("Policy") describes how TimeMaps ("we", "us", or "our") collects, protects,
                        and
                        uses
                        the personally identifiable information ("Personal Information") you ("User", "you", or "your")
                        provide
                        through the TimeMaps website. The Policy also describes the choices available to you regarding
                        our use
                        of
                        your Personal Information and how you can access and update this information.
                    </p>

                    <h2>Collection of personal information</h2>
                    <p>
                        We receive and store any information you knowingly provide to us when you make a purchase
                        through the
                        website. Currently, this is limited to your email address, which is required for you to access
                        purchased
                        TimeMaps products.
                    </p>

                    <h2>Use and processing of collected information</h2>
                    <p>
                        Any of the information we collect from you may be used to personalize your experience, improve
                        our
                        website, improve customer service, process transactions, and operate our website.
                    </p>

                    <h2>Managing personal information</h2>
                    <p>
                        You can update your Personal Information in your account settings. If you delete your
                        information, you
                        may lose access to the products you purchased.
                    </p>

                    <h2>Information security</h2>
                    <p>
                        We secure the information you provide on computer servers in a controlled, secure environment.
                        However, no
                        data transmission over the Internet can be guaranteed to be 100% secure.
                    </p>

                    <h2>Contacting us</h2>
                    <p>
                        If you have any questions about this policy, please contact us at{' '}
                        <a href="mailto:support@timemaps.com">support@timemaps.com</a>.
                    </p>
                </div>
            </div>
            <div className="mt-6  mb-5 text-center text-xs text-gray-400">
                <div className="mt-2 flex justify-center items-center space-x-3">
                    <span className="px-2 py-1">&copy; TimeMaps 2024</span>
                    <Link
                        to="/sign-up"
                        className="hover:bg-gray-200 rounded-md px-2 py-1"
                    >
                        Sign up
                    </Link>
                    <Link
                        to="/sign-in"
                        className="hover:bg-gray-200 rounded-md px-2 py-1"
                    >
                        Sign in
                    </Link>
                    <Link
                        to="/docs"
                        className="hover:bg-gray-200 rounded-md px-2 py-1"
                    >
                        Docs
                    </Link>
                    <Link
                        to="/contact"
                        className="hover:bg-gray-200 rounded-md px-2 py-1"
                    >
                        Contact
                    </Link>
                    <Link
                        to="/terms-of-service"
                        className="hover:bg-gray-200 rounded-md px-2 py-1"
                    >
                        Terms of Service
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
