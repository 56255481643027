import React from 'react';

const Docs: React.FC = () => {
    return (
            <div className="p-6">
                <h1 className="text-3xl font-bold">Documentation</h1>
                <p className="mt-4 text-lg">
                    Welcome to the TimeMaps documentation. Here you can find various articles and resources
                    to help you get started and effectively use TimeMaps.
                </p>
                {/* Add more documentation content here */}
            </div>
    );
};

export default Docs;
