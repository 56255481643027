import React from 'react';
import { Link } from 'react-router-dom';

const TermsOfService: React.FC = () => {
    return (
        <div className="min-h-screen min-w-full flex flex-col justify-center items-center bg-gray-50">
            <div className="w-full max-w-md flex flex-col justify-center items-center px-4 sm:px-6 lg:px-8">
                <Link to="/">
                    <img
                        className="mx-auto h-10 sm:h-14 w-auto select-none"
                        src="/TimeMaps.webp"
                        alt="TimeMaps"
                        draggable="false"
                    />
                </Link>
                <span className="justify-center text-center select-none mt-2 tracking-widest">Time Maps</span>
                <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                    Terms of Service
                </h2>
                <p className="mt-4 mb-10 text-center text-base leading-7 text-slate-600">
                    Effective as of November 2, 2021
                </p>
            </div>

            <div className="relative px-4 sm:px-6 lg:px-8">
                <div
                    className="mx-auto max-w-[40rem] prose-sm prose prose-slate prose-a:font-semibold prose-a:text-sky-500 hover:prose-a:text-sky-600">
                    <p>
                        Welcome to TimeMaps! These terms govern your use of our website. By accessing and using the
                        site, you agree to comply with and be bound by the following terms and conditions.
                    </p>

                    <h2>Use of the website</h2>
                    <p>
                        You agree to use the website only for lawful purposes. You are prohibited from using the site to
                        infringe on others' rights or violate any applicable local, state, national, or international
                        law or regulation.
                    </p>

                    <h2>Account registration</h2>
                    <p>
                        To access some services on our site, you may need to create an account. You are responsible for
                        maintaining the confidentiality of your account information.
                    </p>

                    <h2>Purchases and refunds</h2>
                    <p>
                        All purchases made on TimeMaps are final. We do not offer refunds except as required by
                        applicable law. Please ensure that you understand this before making a purchase.
                    </p>

                    <h2>Limitation of liability</h2>
                    <p>
                        In no event will TimeMaps be liable for any indirect, incidental, special, or consequential
                        damages arising out of or in connection with the use of the website.
                    </p>

                    <h2>Changes to terms</h2>
                    <p>
                        TimeMaps reserves the right to modify these terms at any time. You are encouraged to review the
                        terms regularly to stay informed of updates.
                    </p>

                    <h2>Contact us</h2>
                    <p>
                        For any questions or concerns about these terms, please contact us at{' '}
                        <a href="mailto:support@timemaps.com">support@timemaps.com</a>.
                    </p>
                </div>
            </div>

            <div className="mt-6 mb-5 text-center text-xs text-gray-400">
                <div className="mt-2 flex justify-center items-center space-x-3">
                    <span className="px-2 py-1">&copy; TimeMaps 2024</span>
                    <Link
                        to="/sign-up"
                        className="hover:bg-gray-200 rounded-md px-2 py-1"
                    >
                        Sign up
                    </Link>
                    <Link
                        to="/sign-in"
                        className="hover:bg-gray-200 rounded-md px-2 py-1"
                    >
                        Sign in
                    </Link>
                    <Link
                        to="/docs"
                        className="hover:bg-gray-200 rounded-md px-2 py-1"
                    >
                        Docs
                    </Link>
                    <Link
                        to="/contact"
                        className="hover:bg-gray-200 rounded-md px-2 py-1"
                    >
                        Contact
                    </Link>
                    <Link
                        to="/privacy-policy"
                        className="hover:bg-gray-200 rounded-md px-2 py-1"
                    >
                        Privacy Policy
                    </Link>
                </div>
            </div>

        </div>
    );
};

export default TermsOfService;
