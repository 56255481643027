import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const PasswordResetStart: React.FC = () => {
    const [email, setEmail] = useState('');
    const [honeypot, setHoneypot] = useState(''); // Honeypot field for bot detection
    const [timerPassed, setTimerPassed] = useState(false); // Timer to ensure user interaction
    const [canSubmit, setCanSubmit] = useState(false); // To control submission
    const [backendError, setBackendError] = useState(''); // For backend errors
    const [isSubmitting, setIsSubmitting] = useState(false); // To handle button states
    const [emailError, setEmailError] = useState(''); // For email validation errors
    const [attemptedSubmit, setAttemptedSubmit] = useState(false); // Track if the form was submitted
    const [step, setStep] = useState(1); // Step 1 for form, Step 2 for success, Step 3 for failure

    // Timer for the 5-second delay to allow submission
    useEffect(() => {
        const timer = setTimeout(() => {
            setTimerPassed(true);
        }, 5000); // 5 seconds
        return () => clearTimeout(timer);
    }, []);

    // Enable form submission only if honeypot is empty and timer has passed
    useEffect(() => {
        setCanSubmit(honeypot === '' && timerPassed);
    }, [honeypot, timerPassed]);

    const handleHoneypotChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setHoneypot(e.target.value);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setAttemptedSubmit(true);

        // Validate the email
        const isEmailValid = validateEmail(email);
        setEmailError(isEmailValid ? '' : 'Email is incorrect');

        // Proceed only if there are no errors and the honeypot is clear
        if (!canSubmit || !isEmailValid) {
            return; // User can click, but form won't proceed if there are errors
        }

        setIsSubmitting(true);

        // Simulate success or failure in sending the reset link
        setTimeout(() => {
            const isSuccess = Math.random() > 0.5; // Simulate a 50% chance of success or failure
            setIsSubmitting(false);
            if (isSuccess) {
                setStep(2); // Success state
            } else {
                setStep(3); // Failure state
            }
        }, 2000); // Simulated async delay
    };

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    return (
        <div className="min-h-screen min-w-full flex flex-col justify-center items-center bg-gray-50">
            <div className="w-full max-w-md">
                <div className="flex justify-center">
                    <Link to="/" className="inline-block">
                        <img
                            className="h-10 sm:h-14 w-auto select-none"
                            src="/TimeMaps.webp"
                            alt="TimeMaps"
                            draggable="false"
                        />
                    </Link>
                </div>
                <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                    {step === 1 && 'Reset your password'}
                    {step === 2 && 'Reset link sent'}
                    {step === 3 && 'Failed to send reset link'}
                </h2>
            </div>

            <div className="mt-8 w-full max-w-md">
                <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                    {step === 1 && (
                        <form className="space-y-6" onSubmit={handleSubmit} noValidate>
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-900">
                                    Email address
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                            if (attemptedSubmit) {
                                                const isValid = validateEmail(e.target.value);
                                                setEmailError(isValid ? '' : 'Email is incorrect');
                                            }
                                        }}
                                        className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ${
                                            attemptedSubmit && emailError ? 'ring-red-500' : 'ring-gray-300'
                                        } placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                    />
                                    {attemptedSubmit && emailError && (
                                        <p className="text-red-600 text-sm">{emailError}</p>
                                    )}
                                </div>
                            </div>

                            {/* Invisible honeypot field for bot detection */}
                            <div className="hidden">
                                <label htmlFor="honeypot" className="block text-sm font-medium text-gray-900">
                                    Do not fill this field
                                </label>
                                <input
                                    id="honeypot"
                                    name="honeypot"
                                    type="text"
                                    value={honeypot}
                                    onChange={handleHoneypotChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm"
                                />
                            </div>

                            {/* Backend error message */}
                            {backendError && <p className="text-red-600 text-sm">{backendError}</p>}

                            <div>
                                <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className={`flex w-full justify-center rounded-md ${
                                        isSubmitting
                                            ? 'bg-gray-400 cursor-not-allowed'
                                            : 'bg-indigo-600 hover:bg-indigo-500'
                                    } px-3 py-1.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                                >
                                    {isSubmitting ? (
                                        <span className="flex items-center gap-x-3">
                                            Sending Reset Link...
                                            <svg
                                                className="animate-spin h-5 w-5 mr-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                            >
                                                <circle
                                                    className="opacity-25"
                                                    cx="12"
                                                    cy="12"
                                                    r="10"
                                                    stroke="currentColor"
                                                    strokeWidth="4"
                                                ></circle>

                                                <circle
                                                    className="opacity-75"
                                                    cx="12"
                                                    cy="12"
                                                    r="10"
                                                    stroke="currentColor"
                                                    strokeDasharray="80"
                                                    strokeDashoffset="60"
                                                    strokeWidth="4"
                                                    strokeLinecap="round"
                                                ></circle>
                                            </svg>
                                        </span>
                                    ) : (
                                        'Send Reset Link'
                                    )}
                                </button>
                            </div>
                        </form>
                    )}

                    {step === 2 && (
                        <div className="space-y-6 text-center">
                            <p className="text-md text-gray-600">
                                An email with a password reset link has been sent to the email address you provided.
                            </p>
                            <p className="text-md text-gray-600 font-bold">
                                Please check your inbox, click the link, and follow the steps to reset your password.
                            </p>
                        </div>
                    )}

                    {step === 3 && (
                        <div className="space-y-6 text-center">
                            <p className="text-md text-red-600 font-bold">
                                Failed to send the password reset link. Please try again later.
                            </p>
                            <p className="text-md text-gray-600">
                                If the problem persists, contact support for further assistance.
                            </p>
                        </div>
                    )}
                </div>
                <div className="mt-6  mb-5 text-center text-xs text-gray-400">
                    <div className="mt-2 flex justify-center items-center space-x-3">
                        <span className="px-2 py-1">&copy; TimeMaps 2024</span>
                        <Link
                            to="/contact"
                            className="hover:bg-gray-200 rounded-md px-2 py-1"
                        >
                            Contact
                        </Link>
                        <Link
                            to="/privacy-policy"
                            className="hover:bg-gray-200 rounded-md px-2 py-1"
                        >
                            Privacy Policy
                        </Link>
                        <Link
                            to="/terms-of-service"
                            className="hover:bg-gray-200 rounded-md px-2 py-1"
                        >
                            Terms of Service
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PasswordResetStart;
