import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const PasswordResetEnd: React.FC = () => {
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordErrorSentence, setPasswordErrorSentence] = useState(''); // For password validation errors
    const [repeatPasswordError, setRepeatPasswordError] = useState('');
    const [attemptedSubmit, setAttemptedSubmit] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false); // To handle button states
    const [step, setStep] = useState(1); // Step 1 for form, Step 2 for success message, Step 3 for error message
    const [backendError, setBackendError] = useState(''); // To handle backend errors

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        if (attemptedSubmit) {
            validateEmail(e.target.value);
        }
    };

    const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword(e.target.value);
        if (attemptedSubmit) {
            validatePassword(e.target.value);
        }
    };

    const handleRepeatPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRepeatPassword(e.target.value);
        if (attemptedSubmit) {
            setRepeatPasswordError(e.target.value === newPassword ? '' : 'Passwords do not match');
        }
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setAttemptedSubmit(true);

        const isEmailValid = validateEmail(email);
        const isPasswordValid = validatePassword(newPassword);
        const doPasswordsMatch = newPassword === repeatPassword;

        if (!isEmailValid || !isPasswordValid || !doPasswordsMatch) {
            if (!doPasswordsMatch) setRepeatPasswordError('Passwords do not match');
            return;
        }

        setIsSubmitting(true);

        // Simulate password reset attempt
        setTimeout(() => {
            // Simulating a failure scenario (you can replace this with real backend logic)
            const isPasswordUpdateSuccessful = Math.random() > 0.5; // 50% chance of failure for demonstration

            setIsSubmitting(false);

            if (isPasswordUpdateSuccessful) {
                setStep(2); // Move to success message step
            } else {
                setStep(3); // Move to error message step
                setBackendError('Failed to update the password. Please try again.');
            }
        }, 2000); // Simulated async delay
    };

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailRegex.test(email);
        setEmailError(isValid ? '' : 'Email is incorrect');
        return isValid;
    };

    const validatePassword = (passwordValue: string) => {
        const errors: string[] = [];
        if (passwordValue.length < 8) {
            errors.push('at least 8 characters');
        }
        if (!/[A-Z]/.test(passwordValue)) {
            errors.push('one uppercase letter');
        }
        if (!/[a-z]/.test(passwordValue)) {
            errors.push('one lowercase letter');
        }
        if (!/\d/.test(passwordValue)) {
            errors.push('one digit');
        }

        if (errors.length > 0) {
            setPasswordErrorSentence(`Password must contain ${errors.join(', ')}.`);
            return false;
        } else {
            setPasswordErrorSentence('');
            return true;
        }
    };

    return (
        <div className="min-h-screen min-w-full flex flex-col justify-center items-center bg-gray-50">
            <div className="w-full max-w-md">
                <div className="flex justify-center">
                    <Link to="/" className="inline-block">
                        <img
                            className="h-10 sm:h-14 w-auto select-none"
                            src="/TimeMaps.webp"
                            alt="TimeMaps"
                            draggable="false"
                        />
                    </Link>
                </div>
                <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                    {step === 1 ? 'Update your password' : step === 2 ? 'Password reset successful!' : 'Error'}
                </h2>
            </div>

            <div className="mt-8 w-full max-w-md">
            <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                    {step === 1 && (
                        <form className="space-y-6" onSubmit={handleSubmit} noValidate>
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-900">
                                    Email address (for confirmation)
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        value={email}
                                        onChange={handleEmailChange}
                                        className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ${
                                            attemptedSubmit && emailError ? 'ring-red-500' : 'ring-gray-300'
                                        } placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                    />
                                    {attemptedSubmit && emailError && (
                                        <p className="text-red-600 text-sm">{emailError}</p>
                                    )}
                                </div>
                            </div>

                            <div>
                                <label htmlFor="new-password" className="block text-sm font-medium text-gray-900">
                                    New Password
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="new-password"
                                        name="new-password"
                                        type="password"
                                        value={newPassword}
                                        onChange={handleNewPasswordChange}
                                        className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ${
                                            attemptedSubmit && passwordErrorSentence ? 'ring-red-500' : 'ring-gray-300'
                                        } placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                    />
                                    {attemptedSubmit && passwordErrorSentence && (
                                        <p className="text-red-600 text-sm">{passwordErrorSentence}</p>
                                    )}
                                </div>
                            </div>

                            <div>
                                <label htmlFor="repeat-password" className="block text-sm font-medium text-gray-900">
                                    Repeat New Password
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="repeat-password"
                                        name="repeat-password"
                                        type="password"
                                        value={repeatPassword}
                                        onChange={handleRepeatPasswordChange}
                                        className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ${
                                            attemptedSubmit && repeatPasswordError ? 'ring-red-500' : 'ring-gray-300'
                                        } placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                    />
                                    {attemptedSubmit && repeatPasswordError && (
                                        <p className="text-red-600 text-sm">{repeatPasswordError}</p>
                                    )}
                                </div>
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className={`flex w-full justify-center rounded-md ${
                                        isSubmitting
                                            ? 'bg-gray-400 cursor-not-allowed'
                                            : 'bg-indigo-600 hover:bg-indigo-500'
                                    } px-3 py-1.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                                >
                                    {isSubmitting ? (
                                        <span className="flex items-center gap-x-3">
                                            Updating Password...
                                            <svg
                                                className="animate-spin h-5 w-5 mr-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                            >
                                                <circle
                                                    className="opacity-25"
                                                    cx="12"
                                                    cy="12"
                                                    r="10"
                                                    stroke="currentColor"
                                                    strokeWidth="4"
                                                ></circle>

                                                <circle
                                                    className="opacity-75"
                                                    cx="12"
                                                    cy="12"
                                                    r="10"
                                                    stroke="currentColor"
                                                    strokeDasharray="80"
                                                    strokeDashoffset="60"
                                                    strokeWidth="4"
                                                    strokeLinecap="round"
                                                ></circle>
                                            </svg>
                                        </span>
                                    ) : (
                                        'Update Password'
                                    )}
                                </button>
                            </div>
                        </form>
                    )}

                    {step === 2 && (
                        <div className="space-y-6 text-center">
                            <p className="text-md text-gray-600">
                                Your password has been successfully updated.
                            </p>
                            <p className="text-md text-gray-600 font-bold">
                                You can now try to <Link to="/signin" className="text-blue-600 font-bold">sign
                                in</Link> using your new password.
                            </p>
                        </div>
                    )}

                    {step === 3 && (
                        <div className="space-y-6 text-center">
                            <p className="text-md text-red-600 font-bold">
                                {backendError}
                            </p>
                            <p className="text-md text-gray-600">
                                Please try again or contact support if the issue persists.
                            </p>
                        </div>
                    )}
                </div>
                <div className="mt-6  mb-5 text-center text-xs text-gray-400">
                    <div className="mt-2 flex justify-center items-center space-x-3">
                        <span className="px-2 py-1">&copy; TimeMaps 2024</span>
                        <Link
                            to="/contact"
                            className="hover:bg-gray-200 rounded-md px-2 py-1"
                        >
                            Contact
                        </Link>
                        <Link
                            to="/privacy-policy"
                            className="hover:bg-gray-200 rounded-md px-2 py-1"
                        >
                            Privacy Policy
                        </Link>
                        <Link
                            to="/terms-of-service"
                            className="hover:bg-gray-200 rounded-md px-2 py-1"
                        >
                            Terms of Service
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PasswordResetEnd;
