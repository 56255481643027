import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt, faLock, faSyncAlt, faRadiation, faMountainSun, faAnchor, faFingerprint, faCogs, faServer, faBars, faArrowRight, faTimes, faAt } from '@fortawesome/free-solid-svg-icons';
import { faMapLocation, faUserGroup, faUsers, faWandSparkles, faCloudArrowUp, faCloudArrowDown } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faProductHunt, faYoutube } from '@fortawesome/free-brands-svg-icons';

import { Link } from 'react-router-dom';
const tabs = [
    { name: 'Nuclear Explosions', icon: faRadiation, imgSrc: '/TimeMapsExample1.png' },
    { name: 'Personal Travel History', icon: faMountainSun, imgSrc: '/TimeMapsExample2.png' },
    { name: 'Titanic Sinking', icon: faAnchor, imgSrc: '/TimeMapsExample3.png' },
];

const primaryFeatures = [
    {
        name: 'Create Dynamic Time Maps',
        description:
            'Easily design and visualize complex historical events, journeys, or future plans. Add animated points, lines, polygons, and moving objects, all of which appear and disappear over time based on custom durations.',
        icon: faCloudUploadAlt,
    },
    {
        name: 'Explore Shared Maps',
        description:
            'Discover time maps created by other users in the library. Explore historical battles, travel portfolios, and environmental changes, and gain new insights with dynamic, interactive animations.',
        icon: faServer,
    },
    {
        name: 'Collaborative Timeline Creation',
        description:
            'Work together with others to create shared timelines. Perfect for group projects, research collaborations, or planning future events with friends or colleagues.',
        icon: faSyncAlt,
    },
    {
        name: 'Powerful Visualization Tools',
        description:
            'Leverage our advanced visualization engine to animate geographical changes, personal travel portfolios, and more. Customize pauses, dates, and movement to create immersive animations.',
        icon: faCogs,
    },
    {
        name: 'Advanced Security',
        description:
            'TimeMaps ensures that your private maps are safe and secure. Share with select individuals or make them public for everyone to explore.',
        icon: faLock,
    },
    {
        name: 'Geographical and Historical Insights',
        description:
            'Track and animate geographic changes over time, such as flora and fauna shifts, historical maps, and more, to gain a better understanding of the world’s evolution.',
        icon: faFingerprint,
    },
];




const navigation = [
    { name: 'Features', href: '#features' },
    { name: 'Use Cases', href: '#use-cases' },
    { name: 'Explore', href: '/explore' },
    { name: 'Docs', href: '/docs' },
    { name: 'Create', href: '/new' },
];


const pills = [
    { text: 'Create complex time-lapse maps with ease', icon: faMapLocation, bgColor: 'bg-green-500', textColor: 'text-green-300', ringColor: 'ring-green-400/20' },
    { text: 'Share with anyone you deem fit', icon: faUserGroup, bgColor: 'bg-blue-500', textColor: 'text-blue-300', ringColor: 'ring-blue-400/20' },
    { text: 'Collaborate on complex community projects', icon: faUsers, bgColor: 'bg-purple-500', textColor: 'text-purple-300', ringColor: 'ring-purple-400/20' },
    { text: 'Quickly turn text into a professional time lapse using AI', icon: faWandSparkles, bgColor: 'bg-yellow-500', textColor: 'text-yellow-300', ringColor: 'ring-yellow-400/20' },
    { text: 'Import and improve any of your past maps', icon: faCloudArrowUp, bgColor: 'bg-red-500', textColor: 'text-red-300', ringColor: 'ring-red-400/20' },
    { text: 'Export time maps in a wide variety of file extensions', icon: faCloudArrowDown, bgColor: 'bg-teal-500', textColor: 'text-teal-300', ringColor: 'ring-teal-400/20' }
];



const About: React.FC = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const [userInteracted, setUserInteracted] = useState(false);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const [visiblePill, setVisiblePill] = useState(0);
    const [pillWidth, setPillWidth] = useState(0);
    const [isFading, setIsFading] = useState(false);
    const pillRef = useRef<HTMLSpanElement>(null);

    const PADDING = 32; // Padding value for the pill
    const ICON_WIDTH = 27; // Approximate width for FontAwesomeIcon including margin

    useEffect(() => {
        const interval = setInterval(() => {
            setIsFading(true);
            setTimeout(() => {
                setVisiblePill((prevPill) => (prevPill + 1) % pills.length);
                setIsFading(false);
            }, 1000);
        }, 7000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (pillRef.current) {
            const textWidth = pillRef.current.offsetWidth;
            const newWidth = textWidth + PADDING + ICON_WIDTH;
            setPillWidth(newWidth);
        }
    }, [visiblePill]);


    useEffect(() => {
        if (!userInteracted) {
            intervalRef.current = setInterval(() => {
                setSelectedTab((prevTab) => (prevTab + 1) % tabs.length);
            }, 10000); // Switch every 10 seconds
        }

        return () => {
            if (intervalRef.current) clearInterval(intervalRef.current);
        };
    }, [userInteracted]);

    const handleTabClick = (index: number) => {
        setSelectedTab(index);
        setUserInteracted(true); // Stop auto-switching
        if (intervalRef.current) clearInterval(intervalRef.current);
    };

    return (
        <div className="bg-gray-900 max-w-screen overflow-hidden min-h-screen flex flex-col justify-between w-full">
            <header className="absolute inset-x-0 top-0 z-50">
                <div
                    className="absolute inset-0 h-[300px] w-full z-[-1] pointer-events-none hidden md:block"
                    style={{
                        background: 'linear-gradient(to top, transparent 40%, #111827 100%)',
                    }}
                />
                <nav className="mx-auto max-w-[1500px] w-full flex items-center justify-between p-6 lg:px-8"
                     aria-label="Global">
                    <div className="flex lg:flex-1">
                        <Link to="/" className="-m-1.5 p-1.5">
                            <div className="flex items-center">
                                <img src="/TimeMaps.webp" alt="TM" className="w-10 h-10 mr-2" draggable={false}/>
                                <div className="flex items-baseline">
                                    <span className="font-semibold text-2xl text-white select-none">time</span>
                                    <span className="font-extrabold text-2xl text-white select-none">maps</span>
                                    <span className="font-light text-md text-gray-400 select-none">.co</span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-300"
                            onClick={() => setMobileMenuOpen(true)}
                        >
                            <span className="sr-only">Open main menu</span>
                            <FontAwesomeIcon icon={faBars} className="h-6 w-6" aria-hidden="true"/>
                        </button>
                    </div>
                    <div className="hidden lg:flex lg:gap-x-12">
                        {navigation.map((item) => (
                            <Link key={item.name} to={item.href}
                                  className="text-[15px] font-semibold leading-6 text-gray-300">
                                {item.name}
                            </Link>
                        ))}
                    </div>
                    <div className="hidden lg:flex gap-6 lg:flex-1 lg:justify-end">
                        <Link
                            to="/sign-up"
                            className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                        >
                            Sign up
                        </Link>
                        <Link
                            to="/sign-in"
                            className=" text-sm font-semibold leading-6 text-gray-300 group flex items-center"
                        >
                            Sign in
                            <FontAwesomeIcon
                                icon={faArrowRight}
                                className="ml-1.5 transition-transform duration-300 group-hover:translate-x-2"
                                aria-hidden="true"
                            />
                        </Link>
                    </div>
                </nav>
                {mobileMenuOpen && (
                    <div className="fixed inset-0 z-50 bg-gray-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-700">
                        <div className="flex items-center justify-between">
                            <Link to="/" className="-m-1.5 p-1.5">
                                <div className="flex items-center">
                                    <img src="/TimeMaps.webp" alt="TM" className="w-10 h-10 mr-2" draggable={false}/>
                                    <div className="flex items-baseline">
                                        <span className="font-semibold text-2xl text-white select-none">time</span>
                                        <span className="font-extrabold text-2xl text-white select-none">maps</span>
                                        <span className="font-light text-md text-gray-400 select-none">.co</span>
                                    </div>
                                </div>
                            </Link>
                            <button
                                type="button"
                                className="-m-2.5 rounded-md p-2.5 text-gray-400"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                <span className="sr-only">Close menu</span>
                                <FontAwesomeIcon icon={faTimes} className="h-6 w-6" aria-hidden="true"/>
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                <div className="space-y-2 py-6">
                                    {navigation.map((item) => (
                                        <Link
                                            key={item.name}
                                            to={item.href}
                                            className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-300 hover:bg-gray-700"
                                        >
                                            {item.name}
                                        </Link>
                                    ))}
                                </div>
                                <div className="py-6 flex flex-col space-y-2">
                                    <Link
                                        to="/sign-up"
                                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white bg-indigo-500 hover:bg-indigo-400 shadow-sm"
                                    >
                                        Sign up
                                    </Link>
                                    <Link
                                        to="/sign-in"
                                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-300 hover:bg-gray-700 flex items-center group"
                                    >
                                        Sign in
                                        <FontAwesomeIcon
                                            icon={faArrowRight}
                                            className="ml-1.5 transition-transform duration-300 group-hover:translate-x-2"
                                            aria-hidden="true"
                                        />
                                    </Link>
                                </div>
                            </div>

                        </div>
                    </div>
                )}
            </header>
            <main className="w-full relative z-10 mt-20">
                <div className="relative">
                    <svg
                        className="absolute inset-0 top-[-80px] h-[80vh] w-full stroke-white/15 [mask-image:radial-gradient(130%_180%_at_top_right,white,transparent)] z-[-2]"
                        aria-hidden="true"
                    >
                        <defs>
                            <pattern id="grid-pattern" width={200} height={200} patternUnits="userSpaceOnUse">
                                <path d="M.5 200V.5H200" fill="none" stroke="white" strokeOpacity="0.2"/>
                            </pattern>
                        </defs>
                        <rect width="100%" height="100%" fill="url(#grid-pattern)"/>
                    </svg>
                    <div
                        className="absolute inset-0 top-[-80px] h-[80vh] w-full z-[-1] pointer-events-none hidden md:block"
                        style={{
                            background: 'linear-gradient(to bottom, transparent 60%, #111827 100%)',
                        }}
                    />
                </div>
                <div
                    className="absolute left-1/2 top-[120px] ml-[-200px] -z-10 transform-gpu -translate-x-1/2 scale-[1.5] blur-3xl sm:left-[calc(50%-10rem)]"
                    aria-hidden="true"
                >
                    <div
                        className="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
                        style={{
                            clipPath:
                                'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
                        }}
                    />
                </div>
                <div
                    className="absolute inset-0 top-[71.5vh] w-full h-[300px] z-[10] pointer-events-none hidden md:block"
                    style={{
                        background: '#111827',
                    }}
                />
                <div
                    className="relative  w-full  bg-transparent mt-12"> {/* Ensured this is transparent */}
                    <div className="max-w-[1700px] mx-auto px-6 lg:flex lg:items-center lg:px-8">
                        <div className="max-w-2xl text-center mx-auto lg:mx-0 lg:text-left lg:w-full">
                            <img src="/TimeMaps.webp" alt="TM" className="w-16 h-16 mr-2 mb-6 " draggable={false}/>
                            <div className="mb-8">
                                <div
                                    className={`text-md font-bold tracking-tight sm:text-md inline-flex items-center rounded-full px-4 py-1.5 font-medium ring-1 ring-inset transition-all duration-1000 ease-in-out 
                        ${pills[visiblePill].bgColor} bg-opacity-10 backdrop-blur-sm ${pills[visiblePill].textColor} ${pills[visiblePill].ringColor}`}
                                    style={{
                                        width: `${pillWidth}px`,
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        transition: 'width 1s ease-in-out',
                                    }}
                                >
                                    <span
                                        className={`transition-opacity duration-1000 ease-in-out ${isFading ? 'opacity-0' : 'opacity-100'}`}>
                                        <FontAwesomeIcon icon={pills[visiblePill].icon} className="mr-2"/>
                                    </span>
                                    <span
                                        ref={pillRef}
                                        className={`transition-opacity duration-1000 ease-in-out ${isFading ? 'opacity-0' : 'opacity-100'}`}
                                    >
                                        {pills[visiblePill].text}
                                    </span>
                                </div>
                            </div>

                            <h1 className="text-4xl   font-bold tracking-tight text-white sm:text-6xl">
                                Visualize the Past, Plan the Future
                            </h1>
                            <p className="mt-6 text-lg   leading-8 text-gray-300">
                                TimeMaps allows you to create, animate, and explore timelines like never before. From
                                historical events and travel itineraries to future plans, the possibilities are endless.
                            </p>
                            <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
                                <Link
                                    to="/explore"
                                    className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                                >
                                    Explore Time Maps
                                </Link>
                                <Link
                                    to="/new"
                                    className="text-sm font-semibold leading-6 text-white group flex items-center"
                                >
                                    Create your first time map
                                    <FontAwesomeIcon
                                        icon={faArrowRight}
                                        className="ml-1.5 transition-transform duration-300 group-hover:translate-x-2"
                                        aria-hidden="true"
                                    />
                                </Link>
                            </div>
                        </div>
                        <div className="mt-16 lg:mt-0 lg:ml-10 z-[1000] lg:max-w-none lg:flex-none">
                            <div className="w-full max-w-5xl mx-auto mt-10">
                                <div className="w-full">
                                    <div className="flex justify-start space-x-4 mb-4">
                                        {tabs.map((tab, index) => (
                                            <button
                                                key={index}
                                                onClick={() => handleTabClick(index)}
                                                className={`flex items-center select-none px-3 py-2 rounded-md font-medium focus:outline-none transition-colors whitespace-nowrap ${
                                                    selectedTab === index
                                                        ? 'bg-white/10 backdrop-blur-sm text-white'
                                                        : 'bg-[#111827]/5 backdrop-blur-sm text-gray-300'
                                                }`}
                                            >
                                                <FontAwesomeIcon icon={tab.icon} className="mr-2"/>
                                                {tab.name}
                                            </button>
                                        ))}
                                    </div>
                                    <div className="w-full select-none h-[600px] overflow-hidden rounded-md shadow-lg">
                                        <img
                                            src={tabs[selectedTab].imgSrc}
                                            alt={tabs[selectedTab].name}
                                            className="w-full h-full object-cover"
                                            draggable={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full mx-auto high-z-index mt-20 max-w-7xl px-6 lg:px-8">
                    <div className="w-full mx-auto  max-w-2xl text-center">
                        <h2 className="text-base font-semibold leading-7  text-indigo-400">Explore, Animate, Share</h2>
                        <p className="mt-2 text-3xl font-bold  tracking-tight text-white sm:text-4xl">
                            Everything you need to create stunning time maps
                        </p>
                        <p className="mt-6 text-lg leading-8 text-gray-300">
                            Whether you're planning future trips or visualizing the past, TimeMaps is your tool for
                            creating animated, interactive timelines that are both informative and beautiful.
                        </p>
                    </div>
                    <div className="mt-16 grid gap-16 sm:grid-cols-2 lg:grid-cols-3">
                        {primaryFeatures.map((feature) => (
                            <div
                                key={feature.name}
                                className="relative flex flex-col bg-gray-800 p-6 rounded-lg shadow-md hover:shadow-lg"
                            >
                                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500">
                                    <FontAwesomeIcon icon={feature.icon} className="h-6 w-6 text-white"
                                                     aria-hidden="true"/>
                                </div>
                                <h3 className="mt-6 text-xl font-bold text-white">{feature.name}</h3>
                                <p className="mt-2 text-base text-gray-300">{feature.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="relative mt-32 py-24">
                    <div className="w-full mx-auto max-w-7xl px-6 lg:px-8">
                        <div className="mx-auto max-w-2xl text-center bg-transparent">
                            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Ready to get
                                started?</h2>
                            <p className="mt-6 text-lg leading-8 text-gray-300">
                                Start creating your first time map today. Visualize the past, animate your journeys, and
                                bring history or plans to life.
                            </p>
                            <div className="mt-10 flex justify-center items-center gap-x-6">
                                <Link
                                    to="/explore"
                                    className="rounded-md bg-indigo-500 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                                >
                                    Explore Time Maps
                                </Link>
                                <Link
                                    to="/new"
                                    className="text-sm font-semibold leading-6 text-white group flex items-center"
                                >
                                    Create your first time map
                                    <FontAwesomeIcon
                                        icon={faArrowRight}
                                        className="ml-1.5 transition-transform duration-300 group-hover:translate-x-2"
                                        aria-hidden="true"
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div
                        className="absolute left-1/2 right-0 top-[-350px] -z-10 transform-gpu -translate-x-1/2 scale-[1.5] blur-3xl"
                        aria-hidden="true"
                    >
                        <div
                            className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
                            style={{
                                clipPath:
                                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                            }}
                        />
                    </div>
                </div>
            </main>
            <footer className="bg-gray-900 w-full text-gray-400 mt-auto">
                <div className="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
                    <nav className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12" aria-label="Footer">
                        {navigation.map((item) => (
                            <div key={item.name} className="pb-6">
                                <Link to={item.href} className="text-sm leading-6 text-gray-400 hover:text-gray-300">
                                    {item.name}
                                </Link>
                            </div>
                        ))}
                        <div className="pb-6">
                            <Link to="/contact" className="text-sm leading-6 text-gray-400 hover:text-gray-300">
                                Contact
                            </Link>
                        </div>
                    </nav>

                    <div className="mt-10 flex justify-center space-x-10">
                        <a
                            href="https://www.github.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-gray-400 hover:text-gray-500"
                        >
                            <span className="sr-only">GitHub</span>
                            <FontAwesomeIcon icon={faGithub} className="h-6 w-6"/>
                        </a>
                        <a
                            href="https://www.youtube.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-gray-400 hover:text-gray-500"
                        >
                            <span className="sr-only">YouTube</span>
                            <FontAwesomeIcon icon={faYoutube} className="h-6 w-6"/>
                        </a>
                        <a
                            href="https://www.producthunt.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-gray-400 hover:text-gray-500"
                        >
                            <span className="sr-only">Product Hunt</span>
                            <FontAwesomeIcon icon={faProductHunt} className="h-6 w-6"/>
                        </a>
                        <div className="relative group">
                            <a
                                href="mailto:contact@timemaps.co"
                                className="text-gray-400 hover:text-gray-500"
                            >
                                <FontAwesomeIcon icon={faAt} className="h-6 w-6"/>
                            </a>
                            <span
                                className="absolute left-1/2 transform -translate-x-1/2 bottom-full mb-2 hidden group-hover:block px-2 py-1 text-xs bg-black text-white rounded-md w-[235px] z-50 text-center"
                            >
                Send email to: contact@timemaps.co
            </span>
                        </div>
                    </div>
                    <div className="mt-8 flex justify-center gap-3">
                        <Link
                            to="/privacy-policy"
                            className="text-xs leading-5 text-gray-500 hover:text-gray-300"
                        >
                            Privacy Policy
                        </Link>
                        <Link
                            to="/terms-of-service"
                            className="text-xs leading-5 text-gray-500 hover:text-gray-300"
                        >
                            Terms of Service
                        </Link>
                    </div>
                    <p className="mt-1 text-center text-xs leading-5 text-gray-500">
                        &copy; 2024 TimeMaps, Inc. All rights reserved.
                    </p>
                </div>
            </footer>
        </div>
    );
};

export default About;
