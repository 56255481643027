import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as EmailJS from '@emailjs/browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faAt } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faProductHunt } from '@fortawesome/free-brands-svg-icons';

const Contact: React.FC = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        category: 'General Inquiry',
    });
    const [feedbackMessage, setFeedbackMessage] = useState<string | null>(null);
    const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false); // To handle button states
    const [attemptedSubmit, setAttemptedSubmit] = useState(false); // Track if form submission was attempted
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [messageError, setMessageError] = useState('');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));

        if (attemptedSubmit) {
            validateField(name, value);
        }
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setAttemptedSubmit(true); // Mark that the user attempted to submit

        const isValid = validateForm();
        if (!isValid) return;

        setIsSubmitting(true);
        setFeedbackMessage(null); // Clear previous messages

        EmailJS.send(
            'service_kjnablx',
            'template_sl52osc',
            {
                name: formData.name,
                email: formData.email,
                message: formData.message,
                category: formData.category,
            },
            '8aP43jqr9TxlxeH9q'
        )
            .then(() => {
                setFeedbackMessage('Message sent successfully!');
                setIsSuccess(true);
                setIsSubmitting(false);
                setFormData({
                    name: '',
                    email: '',
                    message: '',
                    category: 'General Inquiry',
                });
            })
            .catch((error: Error) => {
                setFeedbackMessage('Failed to send message.');
                setIsSuccess(false);
                setIsSubmitting(false);
                console.error(error);
            });
    };

    const validateForm = () => {
        const nameValid = validateField('name', formData.name);
        const emailValid = validateField('email', formData.email);
        const messageValid = validateField('message', formData.message);
        return nameValid && emailValid && messageValid;
    };

    const validateField = (name: string, value: string) => {
        let isValid = true;

        if (name === 'name') {
            if (value.trim() === '') {
                setNameError('Name should not be empty');
                isValid = false;
            } else {
                setNameError('');
            }
        }

        if (name === 'email') {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (value.length < 8) {
                setEmailError('Email should be at least 8 characters long');
                isValid = false;
            } else if (!emailPattern.test(value)) {
                setEmailError('Email is incorrect');
                isValid = false;
            } else {
                setEmailError('');
            }
        }

        if (name === 'message') {
            if (value.length < 50 || value.length > 500) {
                setMessageError('Message should be between 50 and 500 characters long');
                isValid = false;
            } else {
                setMessageError('');
            }
        }

        return isValid;
    };

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50 w-full py-8">
            <div className="w-full flex justify-center">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-12 w-full max-w-5xl">
                    <div className="space-y-6 px-6 md:pr-20">
                        <Link to="/" className="inline-block">
                            <img
                                className="h-10 sm:h-14 w-auto select-none"
                                src="/TimeMaps.webp"
                                alt="TimeMaps"
                                draggable="false"
                            />
                        </Link>
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900">Contact Us</h2>
                        <p className="text-gray-700 max-w-[360px]">
                            For any inquiries or questions, please contact us at the address below or fill out the form.
                        </p>
                        <div className="text-gray-900 space-y-2">
                            <p className="font-semibold">TimeMaps Inc.</p>

                            <p>
                                <FontAwesomeIcon icon={faAt} className="mr-2" />
                                <a
                                    href="mailto:info@timemaps.com"
                                    className="inline-flex text-black hover:text-gray-700 transition-colors"
                                >
                                    info@timemaps.com
                                </a>
                            </p>

                            <p>
                                <a
                                    href="https://github.com/kgniewek/timemaps"
                                    className="inline-flex items-center text-black hover:text-gray-600 transition-colors"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FontAwesomeIcon icon={faGithub} className="mr-2" />
                                    kgniewek/timemaps
                                </a>
                            </p>

                            <p>
                                <a
                                    href="https://www.producthunt.com/@krzysztofgniewek"
                                    className="inline-flex items-center text-black hover:text-gray-600 transition-colors"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FontAwesomeIcon icon={faProductHunt} className="mr-2" />
                                    <span>@krzysztofgniewek</span>
                                </a>
                            </p>

                            <p>
                                <FontAwesomeIcon icon={faMapMarkerAlt} className="ml-0.5 mr-2.5" />
                                Warsaw, Poland
                            </p>
                        </div>
                    </div>

                    {/* Right Column: Form */}
                    <div className="flex flex-col justify-between">
                        <div className="bg-white p-6 md:p-10 shadow-lg sm:rounded-lg">
                            <form onSubmit={handleSubmit} className="space-y-6" noValidate>
                                <div>
                                    <label htmlFor="category"
                                           className="block text-sm font-semibold leading-6 text-gray-900">
                                        Category
                                    </label>
                                    <select
                                        id="category"
                                        name="category"
                                        value={formData.category}
                                        onChange={handleChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    >
                                        <option value="General Inquiry">General Inquiry</option>
                                        <option value="Report Issue">Report Issue</option>
                                        <option value="Feature Request">Feature Request</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>

                                <div>
                                    <label htmlFor="name"
                                           className="block text-sm font-semibold leading-6 text-gray-900">
                                        Name
                                    </label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        className={`mt-1 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ${
                                            attemptedSubmit && nameError ? 'ring-red-500' : 'ring-gray-300'
                                        } focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                    />
                                    {attemptedSubmit && nameError &&
                                        <p className="text-red-600 text-sm">{nameError}</p>}
                                </div>

                                <div>
                                    <label htmlFor="email"
                                           className="block text-sm font-semibold leading-6 text-gray-900">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className={`mt-1 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ${
                                            attemptedSubmit && emailError ? 'ring-red-500' : 'ring-gray-300'
                                        } focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                    />
                                    {attemptedSubmit && emailError &&
                                        <p className="text-red-600 text-sm">{emailError}</p>}
                                </div>

                                <div>
                                    <label htmlFor="message"
                                           className="block text-sm font-semibold leading-6 text-gray-900">
                                        Message
                                    </label>
                                    <textarea
                                        id="message"
                                        name="message"
                                        rows={6}
                                        value={formData.message}
                                        onChange={handleChange}
                                        className={`mt-1 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ${
                                            attemptedSubmit && messageError ? 'ring-red-500' : 'ring-gray-300'
                                        } focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                    />
                                    {attemptedSubmit && messageError &&
                                        <p className="text-red-600 text-sm">{messageError}</p>}
                                </div>

                                <div>
                                    <button
                                        type="submit"
                                        disabled={isSubmitting}
                                        className={`flex w-full justify-center rounded-md ${
                                            isSubmitting
                                                ? 'bg-gray-400 cursor-not-allowed'
                                                : 'bg-indigo-600 hover:bg-indigo-500'
                                        } px-3 py-1.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                                    >
                                        {isSubmitting
                                            ? 'Sending...'
                                            : isSuccess === true
                                                ? 'Message Sent'
                                                : isSuccess === false
                                                    ? 'Failed to Send'
                                                    : 'Send Message'}
                                    </button>
                                </div>

                                {feedbackMessage && (
                                    <div
                                        className={`mt-4 text-center ${isSuccess ? 'text-green-500' : 'text-red-500'}`}>
                                        {feedbackMessage}
                                    </div>
                                )}
                            </form>
                        </div>

                        <div className="mt-6  mb-5 text-center text-xs text-gray-400">
                            <div className="mt-2 flex justify-center items-center space-x-3">
                                <span className="px-2 py-1">&copy; TimeMaps 2024</span>
                                <Link
                                    to="/sign-up"
                                    className="hover:bg-gray-200 rounded-md px-2 py-1"
                                >
                                    Sign up
                                </Link>
                                <Link
                                    to="/docs"
                                    className="hover:bg-gray-200 rounded-md px-2 py-1"
                                >
                                    Docs
                                </Link>
                                <Link
                                    to="/privacy-policy"
                                    className="hover:bg-gray-200 rounded-md px-2 py-1"
                                >
                                    Privacy Policy
                                </Link>
                                <Link
                                    to="/terms-of-service"
                                    className="hover:bg-gray-200 rounded-md px-2 py-1"
                                >
                                    Terms of Service
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
