import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Register: React.FC = () => {
    const [step, setStep] = useState(1); // Step management: 1 - form, 2 - success message
    const [honeypot, setHoneypot] = useState(''); // Honeypot field for bot detection
    const [timerPassed, setTimerPassed] = useState(false); // Timer to ensure user interaction
    const [username, setUsername] = useState(''); // Username input
    const [password, setPassword] = useState(''); // Password input
    const [email, setEmail] = useState(''); // Email input
    const [emailError, setEmailError] = useState(''); // Email validation error
    const [passwordErrorSentence, setPasswordErrorSentence] = useState(''); // Password error message as sentence
    const [usernameErrorSentence, setUsernameErrorSentence] = useState(''); // Username error message as sentence
    const [usernameAvailable, setUsernameAvailable] = useState(true); // Username availability
    const [isSubmitting, setIsSubmitting] = useState(false); // To handle button states
    const [attemptedSubmit, setAttemptedSubmit] = useState(false); // Track whether form submission was attempted
    const [backendError, setBackendError] = useState(''); // Backend error message for general issues
    const [termsAccepted, setTermsAccepted] = useState(false); // Checkbox for accepting terms
    const [termsError, setTermsError] = useState(''); // Terms acceptance error message
    const navigate = useNavigate();

    // Handle the honeypot field change
    const handleHoneypotChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setHoneypot(e.target.value);
    };

    // Timer for the 5-second delay to allow submission
    useEffect(() => {
        const timer = setTimeout(() => {
            setTimerPassed(true);
        }, 5000); // 5 seconds
        return () => clearTimeout(timer);
    }, []);

    // Disable submission if honeypot is filled or timer hasn't passed
    const canSubmit =
        honeypot === '' &&
        timerPassed &&
        password !== '' &&
        passwordErrorSentence === '' &&
        usernameErrorSentence === '' &&
        usernameAvailable &&
        emailError === '' &&
        termsAccepted;

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setAttemptedSubmit(true); // Mark that the user attempted to submit
        setBackendError(''); // Clear previous backend errors

        // Email validation
        if (!validateEmail(email)) {
            setEmailError('Email address is incorrect');
        } else {
            setEmailError(''); // Clear error if valid
        }

        // Password and Username validation: Even if fields are empty, show the error message and red border
        validatePassword(password);
        validateUsername(username);

        if (!termsAccepted) {
            setTermsError('Required');
        } else {
            setTermsError('');
        }

        if (!canSubmit) return;

        setIsSubmitting(true); // Set to "Signing Up..." state

        try {
            // Check username availability by calling backend API (pseudo API call)
            const usernameCheckResponse = await checkUsernameAvailability(username);

            if (!usernameCheckResponse.available) {
                setUsernameErrorSentence('Username is taken.');
                setUsernameAvailable(false);
                setIsSubmitting(false); // Re-enable button
                return;
            }

            // Simulate successful registration if everything goes well
            setStep(2); // Move to step 2 after successful registration
        } catch (error) {
            // Handle backend error (general issues)
            setBackendError('Something went wrong. Please try again later.');
            setIsSubmitting(false); // Re-enable button
        }
    };

    // Example function to simulate checking username availability
    const checkUsernameAvailability = async (username: string) => {
        // Replace this with an actual API call
        return new Promise<{ available: boolean }>((resolve, reject) => {
            setTimeout(() => {
                if (username === 'backendError') {
                    reject(new Error('Backend error')); // Simulate backend failure
                } else {
                    resolve({ available: username !== 'takenUsername' });
                }
            }, 1000); // Simulate "takenUsername" as unavailable
        });
    };

    const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(e.target.value);
        setUsernameAvailable(true);

        // Only validate if the user has already attempted to submit
        if (attemptedSubmit) {
            validateUsername(e.target.value);
        }
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);

        // Only validate if the user has already attempted to submit
        if (attemptedSubmit) {
            if (!validateEmail(e.target.value)) {
                setEmailError('Email address is incorrect');
            } else {
                setEmailError(''); // Clear error if valid
            }
        }
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const passwordValue = e.target.value;
        setPassword(passwordValue);

        // Validate the password as the user types
        validatePassword(passwordValue);
    };

    const validatePassword = (passwordValue: string) => {
        // Password validation rules
        const errors: string[] = [];
        if (passwordValue.length < 8) {
            errors.push('at least 8 characters');
        }
        if (!/[A-Z]/.test(passwordValue)) {
            errors.push('one uppercase letter');
        }
        if (!/[a-z]/.test(passwordValue)) {
            errors.push('one lowercase letter');
        }
        if (!/\d/.test(passwordValue)) {
            errors.push('one digit');
        }

        // Construct error sentence
        if (errors.length > 0) {
            setPasswordErrorSentence(`Password must contain ${errors.join(', ')}.`);
        } else {
            setPasswordErrorSentence(''); // Clear if no errors
        }
    };

    const validateUsername = (usernameValue: string) => {
        const errors: string[] = [];
        const specialCharRegex = /[^a-zA-Z0-9]/;

        // Username validation rules
        if (usernameValue.length < 8) {
            errors.push('at least 8 characters');
        }
        if (specialCharRegex.test(usernameValue)) {
            errors.push('no special characters');
        }

        // Construct error sentence
        if (errors.length > 0) {
            setUsernameErrorSentence(`Username must contain ${errors.join(', ')}.`);
        } else {
            setUsernameErrorSentence(''); // Clear if no errors
        }
    };

    // Email validation function
    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    // Handle third-party OAuth login, only proceed if terms are accepted
    const handleSocialLogin = (provider: string) => {
        setAttemptedSubmit(true);

        if (!termsAccepted) {
            setTermsError('Required');
            return; // Block the social login if terms are not accepted
        }

        // Proceed with OAuth login if terms are accepted
        console.log(`Proceeding with ${provider} login`);
    };

    return (
        <div className="min-h-screen min-w-full flex flex-col justify-center items-center bg-gray-50">
            <div className="w-full max-w-md">
                <div className="flex justify-center">
                    <Link to="/" className="inline-block">
                        <img
                            className="h-10 sm:h-14 w-auto select-none"
                            src="/TimeMaps.webp"
                            alt="TimeMaps"
                            draggable="false"
                        />
                    </Link>
                </div>
                <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                    {step === 1 && 'Create an Account'}
                    {step === 2 && 'Registration Successful!'}
                </h2>
            </div>

            <div className="mt-8 w-full max-w-md">
                <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                    {step === 1 && (
                        <form className="space-y-5" onSubmit={handleSubmit} noValidate>
                            <div>
                                <label htmlFor="username" className="block text-sm font-medium text-gray-900">
                                    Username
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="username"
                                        name="username"
                                        type="text"
                                        value={username}
                                        onChange={handleUsernameChange}
                                        required
                                        className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ${
                                            attemptedSubmit && usernameErrorSentence ? 'ring-red-500' : 'ring-gray-300'
                                        } placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                    />
                                    {attemptedSubmit && usernameErrorSentence && (
                                        <p className="text-red-600 text-sm">{usernameErrorSentence}</p>
                                    )}
                                </div>
                            </div>

                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-900">
                                    Email address
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        value={email}
                                        onChange={handleEmailChange}
                                        required
                                        className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ${
                                            attemptedSubmit && emailError ? 'ring-red-500' : 'ring-gray-300'
                                        } placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                    />
                                    {attemptedSubmit && emailError &&
                                        <p className="text-red-600 text-sm">{emailError}</p>}
                                </div>
                            </div>

                            <div>
                                <label htmlFor="password" className="block text-sm font-medium text-gray-900">
                                    Password
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        value={password}
                                        onChange={handlePasswordChange}
                                        required
                                        className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ${
                                            attemptedSubmit && passwordErrorSentence ? 'ring-red-500' : 'ring-gray-300'
                                        } placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                    />
                                    {attemptedSubmit && passwordErrorSentence && (
                                        <p className="text-red-600 text-sm">{passwordErrorSentence}</p>
                                    )}
                                </div>
                            </div>

                            {/* Invisible honeypot field for bot detection */}
                            <div className="hidden">
                                <label htmlFor="honeypot" className="block text-sm font-medium text-gray-900">
                                    Do not fill this field (honeypot)
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="honeypot"
                                        name="honeypot"
                                        type="text"
                                        value={honeypot}
                                        onChange={handleHoneypotChange}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm"
                                    />
                                </div>
                            </div>

                            {/* Checkbox for Terms and Conditions */}
                            <div className="mt-4">
                                <div className="flex items-start">
                                    <div className="flex h-5 items-center">
                                        <input
                                            id="terms"
                                            name="terms"
                                            type="checkbox"
                                            checked={termsAccepted}
                                            onChange={() => setTermsAccepted(!termsAccepted)}
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        />
                                    </div>
                                    <div className="ml-3 text-sm leading-6 mt-[-2px]">
                                        {/* The label is associated with the checkbox via htmlFor */}
                                        <label htmlFor="terms">
                                            By creating an account, you agree to our{' '}
                                            <a
                                                href="/privacy-policy"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="font-semibold text-indigo-600"
                                            >
                                                Privacy Policy
                                            </a>{' '}
                                            and{' '}
                                            <a
                                                href="/terms-of-service"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="font-semibold text-indigo-600"
                                            >
                                                Terms of Service
                                            </a>.
                                        </label>
                                    </div>
                                </div>
                                {/* Error message displayed below the checkbox */}
                                {attemptedSubmit && !termsAccepted && (
                                    <p className="text-red-600 text-sm mt-1">{termsError}</p>
                                )}
                            </div>


                            {/* Backend error message */}
                            {backendError && <p className="text-red-600 text-sm">{backendError}</p>}

                            <div>
                                <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className={`flex w-full justify-center rounded-md ${
                                        isSubmitting
                                            ? 'bg-gray-400 cursor-not-allowed'
                                            : 'bg-indigo-600 hover:bg-indigo-500'
                                    } px-3 py-1.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                                >
                                    {isSubmitting ? (
                                        <span className="flex items-center gap-x-3">
                                            Signing you up...
                                            <svg
                                                className="animate-spin h-4 w-4"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                            >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <circle
                                            className="opacity-75"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeDasharray="80"
                                            strokeDashoffset="60"
                                            strokeWidth="4"
                                            strokeLinecap="round"
                                        ></circle>
                                    </svg>
                                        </span>
                                    ) : (
                                        'Sign Up'
                                    )}
                                </button>
                            </div>
                        </form>
                    )}

                    {step === 2 && (
                        <div className="space-y-6 text-center">
                            <p className="text-sm text-gray-600">
                                A verification email has been sent! Please check your email to verify your account.
                            </p>
                            <p className="text-sm text-gray-600">
                                Haven't received the email?{' '}
                                <Link to="/resend-email"
                                      className="font-semibold text-indigo-600 hover:text-indigo-500">
                                    Resend verification email
                                </Link>.
                            </p>
                        </div>
                    )}

                    {/* Hide the following sections if step is 2 */}
                    {step === 1 && (
                        <>
                            <div className="relative mt-6">
                                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                    <div className="w-full border-t border-gray-200"/>
                                </div>
                                <div className="relative flex justify-center text-sm">
                                    <span className="bg-white px-2 text-gray-500">OR</span>
                                </div>
                            </div>

                            <div className="mt-6 grid grid-cols-1 gap-4">
                                <button
                                    type="button"
                                    onClick={() => handleSocialLogin('Google')}
                                    className="flex w-full items-center justify-center gap-2 rounded-md bg-white border border-gray-300 px-3 py-1.5 text-gray-500 shadow-sm hover:bg-gray-100 hover:border-gray-400 hover:text-gray-600"
                                >
                                    <svg className="h-5 w-5" viewBox="0 0 24 24" fill="none">
                                        <path
                                            d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                                            fill="#EA4335"
                                        />
                                        <path
                                            d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                                            fill="#4285F4"
                                        />
                                        <path
                                            d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                                            fill="#FBBC05"
                                        />
                                        <path
                                            d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                                            fill="#34A853"
                                        />
                                    </svg>
                                    <span className="text-sm font-semibold leading-6 text-gray-900">
                                        Continue with Google
                                    </span>
                                </button>
                            </div>

                            <div className="mt-4 grid grid-cols-2 gap-4">
                                <button
                                    type="button"
                                    onClick={() => handleSocialLogin('Facebook')}
                                    className="flex w-full items-center justify-center gap-2 rounded-md bg-white border border-gray-300 px-3 py-1.5 text-gray-500 shadow-sm hover:bg-gray-100 hover:border-gray-400 hover:text-gray-600"
                                >
                                    <svg viewBox="0 0 24 24" className="h-5 w-5" fill="#1877F2">
                                        <circle cx="12" cy="12" r="12" fill="#1877F2"/>
                                        <path
                                            d="M16.0078 12.7812H13.6836V19.7461H10.8594V12.7812H9.60547V10.2988H10.8594V8.87207C10.8594 7.89062 11.4297 6.08984 13.7539 6.08984L16.1406 6.09766V8.49219H14.5312C14.2305 8.49219 13.6836 8.63867 13.6836 9.22461V10.2988H16.1328L16.0078 12.7812Z"
                                            fill="white"
                                        />
                                    </svg>
                                    <span className="text-sm font-semibold leading-6 text-gray-900">Facebook</span>
                                </button>

                                <button
                                    type="button"
                                    onClick={() => handleSocialLogin('GitHub')}
                                    className="flex w-full items-center justify-center gap-2 rounded-md bg-white border border-gray-300 px-3 py-1.5 text-gray-500 shadow-sm hover:bg-gray-100 hover:border-gray-400 hover:text-gray-600"
                                >
                                    <svg className="h-5 w-5" aria-hidden="true" fill="#181717" viewBox="0 0 20 20">
                                        <path
                                            fillRule="evenodd"
                                            d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                    <span className="text-sm font-semibold leading-6 text-gray-900">GitHub</span>
                                </button>
                            </div>

                            <div className="mt-6">
                                <p className="text-center text-sm text-gray-500">
                                    Already have an account?{' '}
                                    <a href="/sign-in" className="font-semibold text-indigo-600 hover:text-indigo-500">
                                        Sign in
                                    </a>
                                </p>
                            </div>
                        </>
                    )}
                </div>
                <div className="mt-6  mb-5 text-center text-xs text-gray-400">
                    <div className="mt-2 flex justify-center items-center space-x-3">
                        <span className="px-2 py-1">&copy; TimeMaps 2024</span>
                        <Link
                            to="/contact"
                            className="hover:bg-gray-200 rounded-md px-2 py-1"
                        >
                            Contact
                        </Link>
                        <Link
                            to="/privacy-policy"
                            className="hover:bg-gray-200 rounded-md px-2 py-1"
                        >
                            Privacy Policy
                        </Link>
                        <Link
                            to="/terms-of-service"
                            className="hover:bg-gray-200 rounded-md px-2 py-1"
                        >
                            Terms of Service
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;
