import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Point } from './types';
import { MultiSelectComboBox } from '@hilla/react-components/MultiSelectComboBox.js';
import { TabSheet } from '@hilla/react-components/TabSheet.js';
import { Tabs } from '@hilla/react-components/Tabs.js';
import { Tab } from '@hilla/react-components/Tab.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faMapLocationDot, faEye, faLink, faLock, faLocationDot, faCirclePlay, faTimes, faFileImport, faFileExport, faWandMagicSparkles, faRoute, faCircleCheck, faCircleExclamation, faCloudArrowUp, faLocationArrow, faQuestionCircle, faCog, faImage, faAlignJustify, faPlus } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';

interface SelectedPoint {
    latitude: string;
    longitude: string;
    address: string;
}

interface PanelProps {
    selectedPoint: SelectedPoint;
    onAddPoint: (name: string, date: Date, endDate: Date | null, description: string) => void;
    points: Point[];
    onShowPointsClick: () => void;
    onVisualizeTimeMap: (visualizationTime: number) => void;
    onCancelAllVisualizations: () => void;
    timeProgress: number;
    visiblePointsLength: number;
    currentVisualizationTime: number;
    clearSelectedPoint: () => void;
    beginningDate: Date | null;
    currentRealDate: Date | null;
    endDate: Date | null;
    onPauseResumeClick: () => void;
    onRestartClick: () => void;
    onSkipToEndClick: () => void;
    isPaused: boolean;
    setActiveTab: (tab: string) => void;
    onDropdownToggle: () => void;
    showDropdown: boolean;
}

const Panel: React.FC<PanelProps> = ({
                                         selectedPoint,
                                         onAddPoint,
                                         points,
                                         onShowPointsClick,
                                         onVisualizeTimeMap,
                                         onCancelAllVisualizations,
                                         timeProgress,
                                         visiblePointsLength,
                                         currentVisualizationTime,
                                         clearSelectedPoint,
                                         beginningDate,
                                         currentRealDate,
                                         endDate,
                                         onPauseResumeClick,
                                         onRestartClick,
                                         onSkipToEndClick,
                                         isPaused,
                                         setActiveTab,
                                         onDropdownToggle,
                                         showDropdown,
                                     }) => {


    const historyTags = [
        { label: 'Wiek XX', value: 'wiek-xx' },
        { label: 'World War I', value: 'world-war-i' },
        { label: 'World War II', value: 'world-war-ii' },
        { label: 'War', value: 'war' },
        { label: 'Axis', value: 'axis' },
        { label: 'Alliances', value: 'alliances' },
    ];


    const categoryOptions = [
        { label: 'General Inquiry', value: 'General Inquiry' },
        { label: 'Report Issue', value: 'Report Issue' },
        { label: 'Feature Request', value: 'Feature Request' },
        { label: 'Other', value: 'Other' },
        { label: 'History', value: 'History' },
    ];

    const [pointName, setPointName] = useState('');
    const [pointDate, setPointDate] = useState('');
    const [sliderValue, setSliderValue] = useState<number>(120000);
    const [pointDescription, setPointDescription] = useState('');
    const [pointEndDate, setPointEndDate] = useState('');
    const [timeMapName, setTimeMapName] = useState('');
    const [timeMapDescription, setTimeMapDescription] = useState('');
    const [tagline, setTagline] = useState('');
    const [pointLayer, setPointLayer] = useState('');
    const [selectedLayers, setSelectedLayers] = useState<{ label: string; value: string }[]>([]);
    const [visibility, setVisibility] = useState('public');
    const [category, setCategory] = useState('General Inquiry');
    const [tags, setTags] = useState<{ label: string; value: string }[]>([]);
    const [defaultLayers, setDefaultLayers] = useState<string[]>([]);
    const [elementType, setElementType] = useState('point');
    const [showDescription, setShowDescription] = useState(false);
    const [showImage, setShowImage] = useState(false);
    const [multipleLayersEnabled, setMultipleLayersEnabled] = useState(false);
    const [selectedTags, setSelectedTags] = useState<{ label: string; value: string }[]>([]);
    const [layers, setLayers] = useState<{ label: string; value: string }[]>([]);
    const [newLayerName, setNewLayerName] = useState('');

    const [activeTab, setActiveTabState] = useState('details-tab');


    const [selectedVisualizationTime, setSelectedVisualizationTime] = useState('2 min'); // Initial default value


    const [showElements, setShowElements] = useState(false);
    const [autoZoomPan, setAutoZoomPan] = useState(false);
    const [visualizationMode, setVisualizationMode] = useState('');

    // Single declaration of toggleShowDescription and toggleShowImage
    const toggleShowDescription = () => setShowDescription(!showDescription);
    const toggleShowImage = () => setShowImage(!showImage);

    const [isSaving, setIsSaving] = useState(false); // When the saving process is happening
    const [changesSaved, setChangesSaved] = useState(false); // Boolean to control if changes are saved

    const handleTabChange = (tabId: string) => {
        setActiveTabState(tabId);
        setActiveTab(tabId); // Update the parent component or trigger any other logic
    };

    const toggleDropdown = () => {
        onDropdownToggle();
    };




    const handleSave = () => {
        setIsSaving(true); // Simulate saving state
        setTimeout(() => {
            setIsSaving(false); // Simulate end of saving process
            setChangesSaved(true); // After saving, mark changes as saved
        }, 2000); // Simulate 2-second delay for saving
    };

    const handleElementTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setElementType(event.target.value);
    };
    const handleTimeMapNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTimeMapName(event.target.value);
    };

    const handleTimeMapDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTimeMapDescription(event.target.value);
    };

    const handleTaglineChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTagline(event.target.value);
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPointName(event.target.value);
    };

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setPointDescription(event.target.value);
    };

    const handleEndDateChange = (date: string) => {
        setPointEndDate(date);
    };

    const handleDateChange = (date: string) => {
        setPointDate(date);
    };



    const handleAddPoint = () => {
        if (pointDate) {
            onAddPoint(pointName, new Date(pointDate), new Date(pointEndDate), pointDescription);
            clearFormFields();
        }
    };

    const clearFormFields = () => {
        setPointName('');
        setPointDate('');
        setPointEndDate('');
        setPointDescription('');
        clearSelectedPoint();
    };

    const formatDate = (date: Date | string): string => {
        const d = new Date(date);
        const month = `${d.getMonth() + 1}`.padStart(2, '0');
        const day = `${d.getDate()}`.padStart(2, '0');
        const year = d.getFullYear();
        return `${month}/${day}/${year}`;
    };

    const formattedPoints = points.map((point) => ({
        ...point,
        date: formatDate(point.date),
    }));

    const formatTime = (milliseconds: number): string => {
        const minutes = Math.floor(milliseconds / 60000);
        const seconds = Math.floor((milliseconds % 60000) / 1000);
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    const passedTime = (currentVisualizationTime * timeProgress) / 100;
    const formattedPassedTime = formatTime(passedTime);
    const formattedTotalTime = formatTime(currentVisualizationTime);

    const formatVisualizationTime = (timeInSeconds: number): string => {
        if (timeInSeconds < 60) {
            return `${timeInSeconds} sec`; // Less than a minute
        }

        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;

        if (seconds === 0) {
            return `${minutes} min`; // Exact minutes
        }

        const fractionalMinutes = (minutes + seconds / 60).toFixed(1); // Show fractional minutes (e.g., 2.5 min)
        return `${fractionalMinutes} min`;
    };


    const handleVisibilityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVisibility(event.target.value);
    };

    const handleVisualizationModeChange = (event: CustomEvent) => {
        setVisualizationMode(event.detail.value);
    };

    const handleLayerChange = (event: CustomEvent) => {
        setDefaultLayers(event.detail.value);
    };

    const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setCategory(event.target.value);
    };

    const handleTagsChange = (event: CustomEvent) => {
        setTags(event.detail.value);
    };

    const handleAddLayer = () => {
        if (newLayerName.trim() !== '') {
            const newLayer = { label: newLayerName, value: newLayerName.toLowerCase().replace(/\s+/g, '-') };

            // Add the new layer to both layers and default layers
            setLayers((prevLayers) => {
                const updatedLayers = [...prevLayers, newLayer];
                return updatedLayers;
            });

            setDefaultLayers((prevDefaultLayers) => {
                const updatedDefaultLayers = [...prevDefaultLayers, newLayer.value];
                setSelectedLayers((prevSelectedLayers) => {
                    // Force the refresh by creating a new array reference
                    const updatedSelectedLayers = [...prevSelectedLayers, newLayer];
                    return updatedSelectedLayers;
                });
                return updatedDefaultLayers;
            });

            setNewLayerName(''); // Clear the input after adding
        }
    };

    const handleRemoveLayer = (layerValue: string) => {
        // Remove the layer from both layers and default layers
        setLayers((prevLayers) => prevLayers.filter((layer) => layer.value !== layerValue));

        // Also update the defaultLayers to remove the layer
        setDefaultLayers((prevDefaultLayers) => {
            const updatedDefaultLayers = prevDefaultLayers.filter((defaultLayer) => defaultLayer !== layerValue);
            setSelectedLayers((prevSelectedLayers) =>
                prevSelectedLayers.filter((selectedLayer) => selectedLayer.value !== layerValue)
            );
            return updatedDefaultLayers;
        });
    };




    const handleVisualizationTimeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedTime = event.target.value;
        setSelectedVisualizationTime(selectedTime); // Update displayed time
    };




    return (
        <div className="Panel font-sans" style={{ transition: 'height 0.3s ease-in-out' }}>
            <TabSheet>
                <Tabs
                    slot="tabs"
                    className="w-full flex items-center justify-between"
                    onSelectedChanged={(event) => {
                        const tabMapping = ['details-tab', 'elements-tab', 'preview-tab']; // Map indices to tab IDs
                        const selectedTabIndex = event.detail.value;
                        const selectedTab = tabMapping[selectedTabIndex] || 'details-tab'; // Default to 'details-tab' if something goes wrong
                        console.log('Selected Tab:', selectedTab); // For debugging
                        setActiveTab(selectedTab);
                    }}
                >
                    <div className="flex space-x-1 ml-[0px] left-[2px]">
                        <Tab
                            id="details-tab"
                            className={`pb-3.5 leading-tight flex items-center space-x-1 ${
                                activeTab === 'details-tab'
                                    ? 'text-[#005fdb] dark:text-[#4a90e2] transition-none'
                                    : 'text-gray-500 dark:text-white'
                            }`}
                            onClick={() => handleTabChange('details-tab')}
                        >
                            <FontAwesomeIcon
                                icon={faCircleInfo}
                                className={`h-4 w-4 transition-colors duration-300 ${
                                    activeTab === 'details-tab'
                                        ? 'text-[#005fdb] dark:text-[#4a90e2]'
                                        : 'text-gray-400 dark:text-white'
                                }`}
                            />
                            <span>Details</span>
                        </Tab>

                        <Tab
                            id="elements-tab"
                            className={`pb-3.5 leading-tight flex items-center space-x-1 ${
                                activeTab === 'elements-tab'
                                    ? 'text-[#005fdb] dark:text-[#4a90e2] transition-none'
                                    : 'text-gray-500 dark:text-white'
                            }`}
                            onClick={() => handleTabChange('elements-tab')}
                        >
                            <FontAwesomeIcon
                                icon={faMapLocationDot}
                                className={`h-4 w-4 transition-colors duration-300 ${
                                    activeTab === 'elements-tab'
                                        ? 'text-[#005fdb] dark:text-[#4a90e2]'
                                        : 'text-gray-400 dark:text-white'
                                }`}
                            />
                            <span>Elements</span>
                        </Tab>

                        <Tab
                            id="preview-tab"
                            className={`pb-3.5 leading-tight flex items-center space-x-1 ${
                                activeTab === 'preview-tab'
                                    ? 'text-[#005fdb] dark:text-[#4a90e2] transition-none'
                                    : 'text-gray-500 dark:text-white'
                            }`}
                            onClick={() => handleTabChange('preview-tab')}
                        >
                            <FontAwesomeIcon
                                icon={faCirclePlay}
                                className={`h-4 w-4 transition-colors duration-300 ${
                                    activeTab === 'preview-tab'
                                        ? 'text-[#005fdb] dark:text-[#4a90e2]'
                                        : 'text-gray-400 dark:text-white'
                                }`}
                            />
                            <span>Visualization</span>
                        </Tab>
                    </div>

                    <div className="ml-auto mt-1 mr-3 font-sans">
                        <button
                            className={`group h-[30px] w-[170px] rounded-md py-1 flex items-center justify-center transition-colors duration-300 ${
                                !changesSaved && !isSaving
                                    ? 'border border-gray-300 shadow-sm bg-white hover:bg-gray-200 active:bg-gray-300 dark:bg-black dark:hover:bg-gray-800 dark:active:bg-gray-600 dark:border-gray-500'
                                    : 'border border-transparent bg-transparent'
                            }`}
                            onClick={handleSave}
                            disabled={isSaving}
                        >
                            {isSaving ? (
                                <div className="flex items-center gap-x-1">
                                    <svg
                                        className="animate-spin h-4 w-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25 stroke-gray-300 dark:stroke-gray-500"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            strokeWidth="4"
                                        ></circle>
                                        <circle
                                            className="opacity-75 stroke-gray-600 dark:stroke-white"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            strokeDasharray="80"
                                            strokeDashoffset="60"
                                            strokeWidth="4"
                                            strokeLinecap="round"
                                        ></circle>
                                    </svg>
                                    <span className="dark:text-white">Saving...</span>
                                </div>
                            ) : changesSaved ? (
                                <div className="flex items-center gap-x-1">
                                    <FontAwesomeIcon icon={faCircleCheck} className="text-green-600"/>
                                    <span className="dark:text-white">All changes saved</span>
                                </div>
                            ) : (
                                <div className="flex items-center gap-x-1">
            <span className="flex items-center gap-x-1">
                <span className="group-hover:hidden flex items-center gap-x-1 dark:text-white">
                    <FontAwesomeIcon icon={faCircleExclamation} className="text-red-600"/>
                    <span>Unsaved changes</span>
                </span>
                <span className="hidden group-hover:flex items-center gap-x-1 dark:text-white">
                    <FontAwesomeIcon icon={faCloudArrowUp} className="text-blue-600"/>
                    <span>Save changes</span>
                </span>
            </span>
                                </div>
                            )}
                        </button>


                    </div>
                </Tabs>

                <div {...{tab: 'details-tab'}} className="p-2 font-sans">
                    <div className="flex font-sans  w-full gap-3 mb-3 mt-1">
                        <div className="flex-grow">
                            <label
                                className="block mb-1 text-sm font-semibold text-gray-900 dark:text-white">
                                Title
                            </label>
                            <input
                                id="timeMapName"
                                type="text"
                                value={timeMapName}
                                maxLength={50}
                                onChange={handleTimeMapNameChange}
                                className="block h-[32px] w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6
               dark:bg-black dark:text-white dark:ring-gray-600 dark:focus:ring-indigo-400"
                            />

                        </div>

                        <div className="w-[30%]">
                            <label className="block mb-1 text-sm font-semibold text-gray-900 dark:text-white">
                                Category
                            </label>
                            <select
                                id="category"
                                name="category"
                                value={category}
                                onChange={(event) => setCategory(event.target.value)}
                                className="h-[32px] block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm
                   dark:bg-black dark:text-white dark:border-gray-600 dark:focus:ring-indigo-400 dark:focus:border-indigo-400"
                                style={{
                                    lineHeight: '1.5',
                                    padding: '0 11px',
                                    fontSize: '14px',
                                }}
                            >
                                {categoryOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>

                    </div>

                    <div className="mb-3">
                        <label htmlFor="tagline"
                               className="block mb-1 text-sm font-semibold text-gray-900 dark:text-white">
                            Tagline
                        </label>
                        <input
                            id="tagline"
                            type="text"
                            value={tagline}
                            maxLength={100}
                            onChange={handleTaglineChange}
                            className="block h-[32px] w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6
               dark:bg-black dark:text-white dark:ring-gray-600 dark:focus:ring-indigo-400"
                        />
                    </div>

                    <div className="mb-3">
                        <div className="flex justify-between items-center mb-[2px] relative">
                            <label htmlFor="timeMapDescription" className="block text-sm font-semibold text-gray-900 dark:text-white">
                                Description
                            </label>
                            <div className="relative group">
                                <FontAwesomeIcon icon={faQuestionCircle} className="text-sm text-gray-900 dark:text-white ml-2 " />
                                <span
                                    className="absolute right-0 bottom-full mb-1 hidden group-hover:block px-2 py-1 text-xs bg-black text-white rounded-md w-max z-50">
    You can use Markdown to format the description:
    <br/># Header
    <br/>- Bullet list
    <br/>1. Numbered list
    <br/>[Link title](https://example.com)
    <br/><br/>Citing sources through links or otherwise is encouraged.
</span>

                            </div>
                        </div>
                        <textarea
                            id="timeMapDescription"
                            value={timeMapDescription}
                            maxLength={1000}
                            onChange={handleTimeMapDescriptionChange}
                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6
               dark:bg-black dark:text-white dark:ring-gray-600 dark:focus:ring-indigo-400"
                            style={{ minHeight: '130px' }}
                        />
                    </div>

                    <div className="w-full mt-3">
                        <label htmlFor="visibility" className="block mb-1 text-sm font-semibold text-gray-900 dark:text-white">
                            Visibility
                        </label>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-3 w-full">
                            {[
                                {
                                    label: 'Public',
                                    description: 'Visible to everyone on Explore and Search',
                                    value: 'public',
                                    icon: faEye,
                                },
                                {
                                    label: 'Unlisted',
                                    description: 'Viewable through your profile or via a link',
                                    value: 'unlisted',
                                    icon: faLink,
                                },
                                {
                                    label: 'Private',
                                    description: 'Only you and selected users can view it',
                                    value: 'private',
                                    icon: faLock,
                                },
                            ].map((option) => (
                                <label
                                    key={option.value}
                                    htmlFor={option.value}
                                    className={`relative flex items-start pl-2 pr-2 pt-1 pb-2 rounded-lg border cursor-pointer select-none bg-white dark:bg-black border-gray-300 dark:border-gray-600 ${
                                        visibility === option.value ? 'ring-2 ring-indigo-600' : ''
                                    }`}
                                >
                                    <input
                                        type="radio"
                                        name="visibility"
                                        value={option.value}
                                        id={option.value}
                                        checked={visibility === option.value}
                                        onChange={handleVisibilityChange}
                                        className="hidden"
                                    />
                                    <div className="flex flex-col w-full">
                                        <span className="block text-sm font-semibold text-gray-900 dark:text-white">{option.label}</span>
                                        <span className="text-xs text-gray-500 dark:text-gray-400">{option.description}</span>
                                    </div>
                                    {visibility === option.value && (
                                        <FontAwesomeIcon icon={option.icon} style={{ fontSize: '16px' }} className="absolute top-2 right-2 text-indigo-600" />
                                    )}
                                </label>
                            ))}
                        </div>
                    </div>
                    {category === 'History' && (
                        <div className="w-full mt-3">
                            <label className="block mb-[2px] text-sm font-semibold text-gray-900 dark:text-white">
                                History tags
                            </label>
                            <MultiSelectComboBox
                                itemLabelPath="label"
                                itemIdPath="value"
                                items={historyTags}
                                selectedItems={selectedTags}
                                onChange={(event) => setSelectedTags(event.target.selectedItems)}
                                className="w-full antistyle"
                            />
                        </div>
                    )}

                    <div className="flex items-center justify-between mt-4">
    <span className="flex flex-grow flex-col">
        <span className="text-sm leading-5 font-semibold text-gray-900 dark:text-white">
            Enable multiple layers (recommended for complex maps)
        </span>
        <span className="text-sm text-gray-600 dark:text-gray-400">
            Allow users to toggle layers to filter elements for easier navigation.
        </span>
    </span>
                        <button
                            onClick={() => setMultipleLayersEnabled(!multipleLayersEnabled)}
                            className={`${multipleLayersEnabled ? 'bg-indigo-600' : 'bg-gray-300'} relative inline-flex h-6 w-11 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none`}
                        >
                            <span className="sr-only">Toggle multiple layers</span>
                            <span className={`${multipleLayersEnabled ? 'translate-x-5' : 'translate-x-0'} inline-block h-5 w-5 transform bg-white rounded-full transition duration-200 ease-in-out relative`}>
            <span className={`${multipleLayersEnabled ? 'opacity-0' : 'opacity-100'} absolute inset-0 flex items-center justify-center transition-opacity`} aria-hidden="true">
                <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                    <path
                        d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </span>
            <span className={`${multipleLayersEnabled ? 'opacity-100' : 'opacity-0'} absolute inset-0 flex items-center justify-center transition-opacity`} aria-hidden="true">
                <svg className="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                    <path
                        d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
                    />
                </svg>
            </span>
        </span>
                        </button>
                    </div>



                    {multipleLayersEnabled && (
                        <div className="w-full mt-3.5">
                            <label className="block mb-1 text-sm font-semibold text-gray-900 dark:text-white">
                                Add or remove layers
                            </label>
                            <div className="flex flex-wrap gap-3">
                                {layers.map((layer) => (
                                    <div key={layer.value} className="flex h-[32px] items-center border border-gray-300 dark:border-gray-600 rounded-md px-2 py-1">
                                        <span className="text-sm text-gray-900 dark:text-gray-100 mr-2">{layer.label}</span>
                                        <button onClick={() => handleRemoveLayer(layer.value)} className="text-red-600 hover:text-red-800 flex items-center justify-center">
                                            <FontAwesomeIcon icon={faTimes} />
                                        </button>
                                    </div>
                                ))}
                                {/* Add new layer input and plus button */}
                                <div className="flex gap-1 mb-3 items-center">
                                    <input
                                        type="text"
                                        value={newLayerName}
                                        onChange={(e) => setNewLayerName(e.target.value)}
                                        className="w-[135px] h-[32px] block rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6
               dark:bg-black dark:text-white dark:ring-gray-600 dark:focus:ring-indigo-400"
                                        placeholder="New Layer"
                                    />
                                    <button
                                        onClick={handleAddLayer}
                                        className="w-[32px] h-[32px] items-center justify-center bg-white border border-gray-300 dark:border-gray-600 shadow-sm rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-600 transition dark:bg-black dark:text-white  dark:focus:ring-indigo-400"
                                    >
                                        <FontAwesomeIcon icon={faPlus} className="text-sm" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}





                </div>

                <div {...{tab: 'elements-tab'}} className="p-2 font-sans">
                    <div className="w-full mb-3 mt-1">
                        <label htmlFor="type"
                               className="block mb-1 text-sm font-semibold text-gray-900 dark:text-white">
                            New element type
                        </label>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-3 w-full">
                        {[
                                {
                                    label: 'Static point',
                                    description: 'Fixed location, can appear and disappear over time',
                                    value: 'point',
                                    icon: faLocationDot,
                                },
                                {
                                    label: 'Static line',
                                    description: 'Polyline or polygon connecting points',
                                    value: 'line',
                                    icon: faRoute,
                                },
                                {
                                    label: 'Moving object',
                                    description: 'Point that changes its location over time',
                                    value: 'movingPoint',
                                    icon: faLocationArrow,
                                },
                            ].map((option) => (
                                <label
                                    key={option.value}
                                    htmlFor={option.value}
                                    className={`relative flex items-start pl-2 pr-2 pt-1 pb-2 rounded-lg border cursor-pointer select-none bg-white dark:bg-black border-gray-300 dark:border-gray-600 ${
                                        elementType === option.value ? 'ring-2 ring-indigo-600' : ''
                                    }`}
                                >
                                    <input
                                        type="radio"
                                        name="elementType"
                                        value={option.value}
                                        id={option.value}
                                        checked={elementType === option.value}
                                        onChange={handleElementTypeChange}
                                        className="hidden"
                                    />
                                    <div className="flex flex-col w-full">
                                        <span className="block text-sm font-semibold text-gray-900 dark:text-white">{option.label}</span>
                                        <span className="text-xs text-gray-500 dark:text-gray-400">{option.description}</span>
                                    </div>
                                    {elementType === option.value && (
                                        <FontAwesomeIcon icon={option.icon} style={{ fontSize: '16px' }} className="absolute top-2 right-2 text-indigo-600" />
                                    )}
                                </label>
                            ))}
                        </div>
                    </div>

                    <div className="mb-3 flex items-center gap-3">
                        <div className="flex-grow">
                            <label htmlFor="pointName" className="block mb-1 text-sm font-semibold text-gray-900 dark:text-white ">
                                Element name
                            </label>
                            <input
                                id="pointName"
                                type="text"
                                value={pointName}
                                maxLength={30}
                                onChange={handleNameChange}
                                className="block w-full h-[32px] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-black dark:text-white dark:ring-gray-600 dark:focus:ring-indigo-400"
                            />
                        </div>

                        <div className="flex-shrink-0">
                            <label htmlFor="iconButton" className="block mb-1 text-sm font-semibold text-gray-900 dark:text-white">
                                Icon
                            </label>
                            <button
                                id="iconButton"
                                className="relative group w-8 h-8 border border-gray-300 shadow-sm rounded-md bg-white  dark:hover:bg-gray-800  dark:border-gray-600 dark:bg-black dark:text-white hover:bg-gray-200 active:bg-gray-300 flex justify-center items-center p-1"
                            >
                                <FontAwesomeIcon icon={faLocationDot} className="dark:text-white text-black" />
                            </button>
                        </div>
                    </div>

                    <div className="grid grid-cols-5 gap-3 mb-3">
                        <div className="col-span-1">
                            <label htmlFor="latitude"
                                   className="block mb-1 text-sm font-semibold text-gray-900 dark:text-white">
                                Latitude
                            </label>
                            <input
                                id="latitude"
                                type="text"
                                value={selectedPoint.latitude}
                                readOnly
                                className="block h-[32px] w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-black dark:text-white dark:ring-gray-600 dark:focus:ring-indigo-400"
                            />
                        </div>

                        <div className="col-span-1">
                            <label htmlFor="longitude"
                                   className="block mb-1 text-sm font-semibold text-gray-900 dark:text-white">
                                Longitude
                            </label>
                            <input
                                id="longitude"
                                type="text"
                                value={selectedPoint.longitude}
                                readOnly
                                className="block h-[32px] w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-black dark:text-white dark:ring-gray-600 dark:focus:ring-indigo-400"
                            />
                        </div>

                        <div className="col-span-3">
                            <div className="flex justify-between items-center mb-[-2px] relative">
                                <label htmlFor="address"
                                       className="block mt-[-4px] text-sm font-semibold text-gray-900 dark:text-white">
                                    Address
                                </label>
                                <div className="relative mt-[-2px] mb-[2px] group">
                                    <FontAwesomeIcon icon={faQuestionCircle}
                                                     className="text-sm text-gray-900 dark:text-white  ml-2 "/>
                                    <span
                                        className="absolute right-0 bottom-full  hidden group-hover:block px-2 py-1 text-xs bg-black text-white rounded-md w-[235px] z-50">
                    Right-click on the map to select a location, and the coordinates will automatically populate the input fields.
                </span>
                                </div>
                            </div>
                            <input
                                id="address"
                                type="text"
                                value={selectedPoint.address}
                                readOnly
                                className="block h-[32px] w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-black dark:text-white dark:ring-gray-600 dark:focus:ring-indigo-400"
                            />
                        </div>
                    </div>


                    <div className={`grid w-full gap-3 mb-3 ${multipleLayersEnabled ? 'grid-cols-3' : 'grid-cols-2'}`}>
                        <div className="col-span-1">
                            <label htmlFor="startDate"
                                   className="block mb-1 text-sm font-semibold text-gray-900 dark:text-white">
                                Date
                            </label>
                            <input
                                id="startDate"
                                type="date"
                                value={pointDate}
                                onChange={(event) => setPointDate(event.target.value)}
                                className="block h-[32px] w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm dark:bg-black dark:text-white dark:ring-gray-600 dark:focus:ring-indigo-400"
                            />
                        </div>

                        <div className="col-span-1">
                            <label htmlFor="endDate"
                                   className="block mb-1 text-sm font-semibold text-gray-900 dark:text-white">
                                Ending
                            </label>
                            <input
                                id="endDate"
                                type="date"
                                value={pointEndDate}
                                onChange={(event) => handleEndDateChange(event.target.value)}
                                className="block h-[32px] w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm dark:bg-black dark:text-white dark:ring-gray-600 dark:focus:ring-indigo-400"
                            />
                        </div>

                        {multipleLayersEnabled && (
                            <div className="col-span-1">
                                <label htmlFor="pointLayer"
                                       className="block mb-1 text-sm font-semibold text-gray-900 dark:text-white">
                                    Layer
                                </label>
                                <select
                                    id="pointLayer"
                                    name="pointLayer"
                                    value={pointLayer}
                                    onChange={(e) => setPointLayer(e.target.value)}
                                    className="block h-[32px] w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm
                   dark:bg-black dark:text-white dark:ring-gray-600 dark:focus:ring-indigo-400 dark:focus:border-indigo-400"
                                >
                                    {layers.map((item) => (
                                        <option key={item.value} value={item.value}>
                                            {item.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                        )}
                    </div>

                    {showDescription && (
                        <div className="mb-3">
                            <label htmlFor="pointDescription"
                                   className="block mb-1 text-sm font-semibold text-gray-900 dark:text-white">
                                Description
                            </label>
                            <textarea
                                id="pointDescription"
                                value={pointDescription}
                                maxLength={250}
                                onChange={handleDescriptionChange}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-black dark:text-white dark:ring-gray-600 dark:focus:ring-indigo-400"
                                style={{ width: '100%', minHeight: '40px', maxHeight: '150px' }}
                            />
                        </div>
                    )}

                    {showImage && (
                        <div className="mb-4">
                            <label htmlFor="pointImage" className="block mb-1 text-sm font-semibold text-gray-900 dark:text-white">
                                Adding image tool here
                            </label>
                        </div>
                    )}

                    <div className="flex justify-end space-x-3 mt-4">

                        <div className="flex  space-x-2.5 ">

                            <button
                                className="relative group w-8 h-8  border border-gray-300 shadow-sm rounded-md  bg-white hover:bg-gray-200 flex justify-center items-center p-1 dark:hover:bg-gray-800 active:bg-gray-300  dark:bg-black dark:text-white dark:border-gray-600"
                            >
                                <FontAwesomeIcon icon={faCog} className="text-sm"/>
                                <span
                                    className="absolute left-1/2 font-semibold transform -translate-x-1/2 bottom-full mb-1 hidden group-hover:block px-1 py-1 text-xs bg-black text-white rounded-md">
                            Preferences
                        </span>
                            </button>

                            <button
                                className={`relative group w-8 h-8  border border-gray-300  dark:border-gray-600  dark:hover:bg-gray-800 dark:bg-black dark:text-white shadow-sm rounded-md   flex justify-center items-center p-1 ${showImage ? 'bg-gray-200 ' : 'bg-white  hover:bg-gray-200'} active:bg-gray-400`}
                                onClick={toggleShowImage}
                            >
                                <FontAwesomeIcon icon={faImage} className="text-sm"/>
                                <span
                                    className="absolute  w-[96px] font-semibold left-1/2 transform -translate-x-1/2 bottom-full mb-1 hidden group-hover:block px-1 py-1 text-xs bg-black text-white rounded-md">
                            Add an image to this element
                        </span>
                            </button>
                            <button
                                className={`relative group w-8 h-8   border border-gray-300 shadow-sm rounded-md  dark:hover:bg-gray-800  dark:border-gray-600  dark:bg-black dark:text-white flex justify-center items-center p-1 ${showDescription ? 'bg-gray-200 ' : 'bg-white bg-gray-200 hover:bg-gray-200'} active:bg-gray-400`}
                                onClick={toggleShowDescription}
                            >
                                <FontAwesomeIcon icon={faAlignJustify} className="text-sm"/>
                                <span
                                    className="absolute  w-[112px] font-semibold left-1/2 transform -translate-x-1/2 bottom-full mb-1 hidden group-hover:block px-1 py-1 text-xs bg-black text-white rounded-md">
                            Add a description to this element
                        </span>
                            </button>
                        </div>
                        <button
                            className="px-3 h-8 bg-white  border border-gray-300 shadow-sm rounded-md  dark:hover:bg-green-400  dark:border-gray-600 dark:bg-black dark:text-green-400 text-green-600 dark:hover:text-black dark:active:bg-green-200 hover:text-white  font-inter tracking-wide  hover:bg-green-600 active:bg-green-700 flex items-center">
                            <FontAwesomeIcon icon={faPlus} className="mr-1 text-sm"/>
                            <span className="text-sm font-extrabold">Create element</span>
                        </button>
                    </div>
                </div>

                <div {...{tab: 'preview-tab'}} className="p-2 font-sans">
                <div className=" space-y-4 mb-3 mt-1">
                        <div className="flex items-center justify-between">
                        <span className="flex flex-grow flex-col">
                            <span className="text-sm leading-5 font-semibold text-gray-900 dark:text-white">
                                Show name labels alongside elements' icons and polylines
                            </span>
                            <span className="text-sm text-gray-600 dark:text-gray-400">
                                When off, users can still view names by hovering over the elements.
                            </span>
                        </span>
                            <button
                                onClick={() => setShowElements(!showElements)}
                                className={`${showElements ? 'bg-indigo-600' : 'bg-gray-300'} relative inline-flex h-6 w-11 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none`}
                            >
                                <span className="sr-only">Toggle elements visibility</span>
                                <span
                                    className={`${showElements ? 'translate-x-5' : 'translate-x-0'} inline-block h-5 w-5 transform bg-white rounded-full transition duration-200 ease-in-out relative`}
                                >
                                <span
                                    className={`${showElements ? 'opacity-0' : 'opacity-100'} absolute inset-0 flex items-center justify-center transition-opacity`}
                                    aria-hidden="true"
                                >
                                    <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                                        <path
                                            d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </span>
                                <span
                                    className={`${showElements ? 'opacity-100' : 'opacity-0'} absolute inset-0 flex items-center justify-center transition-opacity`}
                                    aria-hidden="true"
                                >
                                    <svg className="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                                        <path
                                            d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
                                        />
                                    </svg>
                                </span>
                            </span>
                            </button>
                        </div>

                        <div className="flex items-center justify-between">
                        <span className="flex flex-grow flex-col">
                            <span className="text-sm leading-5 font-semibold text-gray-900 dark:text-white">
                                Auto zoom and pan to focus on newly appearing elements
                            </span>
                            <span className="text-sm text-gray-600 dark:text-gray-400">
                                Smoothly adjusts the view. When off, users can still enable it in options.
                            </span>
                        </span>
                            <button
                                onClick={() => setAutoZoomPan(!autoZoomPan)}
                                className={`${autoZoomPan ? 'bg-indigo-600' : 'bg-gray-300'} relative inline-flex h-6 w-11 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none`}
                            >
                                <span className="sr-only">Toggle auto zoom/pan</span>
                                <span
                                    className={`${autoZoomPan ? 'translate-x-5' : 'translate-x-0'} inline-block h-5 w-5 transform bg-white rounded-full transition duration-200 ease-in-out relative`}
                                >
                                <span
                                    className={`${autoZoomPan ? 'opacity-0' : 'opacity-100'} absolute inset-0 flex items-center justify-center transition-opacity`}
                                    aria-hidden="true"
                                >
                                    <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                                        <path
                                            d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </span>
                                <span
                                    className={`${autoZoomPan ? 'opacity-100' : 'opacity-0'} absolute inset-0 flex items-center justify-center transition-opacity`}
                                    aria-hidden="true"
                                >
                                    <svg className="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                                        <path
                                            d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
                                        />
                                    </svg>
                                </span>
                            </span>
                            </button>
                        </div>
                    </div>

                    {multipleLayersEnabled && (
                        <div className="w-full">
                            <label className="block mb-1 text-sm font-semibold text-gray-900 dark:text-white">
                                Layers visible by default
                            </label>
                            <MultiSelectComboBox
                                itemLabelPath="label"
                                itemIdPath="value"
                                items={layers}
                                selectedItems={selectedLayers}
                                onChange={(event) => setSelectedLayers(event.target.selectedItems)}
                                className="w-full antistyle"
                            />
                        </div>
                    )}

                    <div className="mt-4">
                        <div className="relative"> {/* Added relative position to the parent div */}
                            <div className="flex justify-between items-start">
                                {/* Time selection with label (left-aligned) */}
                                <div className="flex flex-col">
                                    <label
                                        htmlFor="visualizationTime"
                                        className="block mb-1 text-sm font-semibold text-gray-900 dark:text-white"
                                    >
                                        Default visualization time
                                    </label>
                                    <div className="flex gap-2">
                                        <select
                                            id="visualizationTime"
                                            defaultValue="120" // Use appropriate value for the default
                                            onChange={handleVisualizationTimeChange} // Handle change to update selected label
                                            className="block mb-[2px] h-[32px] w-[170px] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm
                   dark:bg-black dark:text-white dark:ring-gray-600 dark:focus:ring-indigo-400 dark:focus:border-indigo-400"
                                        >
                                            <option value="30">30 sec</option>
                                            <option value="60">1 min</option>
                                            <option value="90">1,5 min</option>
                                            <option value="120">2 min</option>
                                            <option value="150">2,5 min</option>
                                            <option value="180">3 min</option>
                                            <option value="300">5 min</option>
                                            <option value="600">10 min</option>
                                        </select>
                                    </div>

                                </div>

                                {/* Visualization button with dropdown */}
                                <div className="flex flex-col mt-[-4px] items-start relative">
                                    <label className="block mb-1 text-sm font-semibold text-gray-900 dark:text-white">
                                        Preview
                                    </label>
                                    <div className="flex w-full space-x-[2px]">
                                        {/* Main button - Visualize */}
                                        <button
                                            className="flex-grow-[9] w-[220px] px-4 py-2 h-[46px] bg-green-500 hover:bg-green-600 text-white rounded-l-md focus:outline-none"
                                            onClick={() => onVisualizeTimeMap(sliderValue)}
                                        >
    <span className="flex items-center justify-between w-full">
        <span className="flex items-center">
            <FontAwesomeIcon className="w-5 h-5 mr-1.5" icon={faCirclePlay}/>
            <span className="font-sans font-bold">Visualize</span>
        </span>
       <span className="flex items-center text-green-200 min-w-[60px] justify-end whitespace-nowrap">
           <FontAwesomeIcon icon={faClock} className="ml-4"/>
    <span className="ml-1">{selectedVisualizationTime}</span>
</span>

    </span>
                                        </button>


                                        {/* Dropdown trigger button */}

                                        <button
                                            className="w-6 h-[46px] bg-green-500 hover:bg-green-600 text-white rounded-r-md flex items-center justify-center"
                                            onClick={onDropdownToggle} // Use the prop function here
                                            onBlur={() => {
                                                if (showDropdown) onDropdownToggle(); // Close dropdown on blur
                                            }}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                className="w-5 h-5"
                                            >
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                      d="M19 9l-7 7-7-7"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>
            </TabSheet>
        </div>
    );
};

export default Panel;
