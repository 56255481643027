// ElementGrid.tsx
import React from 'react';
import { Point } from './types';

interface ElementGridProps {
    points: Point[];
}

const ElementGrid: React.FC<ElementGridProps> = ({ points }) => {
    if (points.length === 0) {
        return (
            <div className="flex items-center select-none justify-center w-full h-8">
                <p className="text-gray-700 dark:text-gray-300 text-center">No elements found. Create your first element in the panel above.</p>
            </div>
        );
    }

    const formatDate = (date: Date | string): string => {
        const d = new Date(date);
        const month = `${d.getMonth() + 1}`.padStart(2, '0');
        const day = `${d.getDate()}`.padStart(2, '0');
        const year = d.getFullYear();
        return `${month}/${day}/${year}`;
    };

    const formattedPoints = points.map((point) => ({
        ...point,
        date: formatDate(point.date),
    }));

    return (
        <div className="w-full h-auto">
            <h3 className="text-lg font-semibold mb-2 dark:text-white">Created Elements</h3>
            <div className="max-h-96 overflow-y-auto">
                <table className="table-auto w-full">
                    <thead>
                    <tr>
                        <th className="px-4 py-2 text-left dark:text-white">Date</th>
                        <th className="px-4 py-2 text-left dark:text-white">Name</th>
                        <th className="px-4 py-2 text-left dark:text-white">Address</th>
                    </tr>
                    </thead>
                    <tbody>
                    {formattedPoints.map((point, index) => (
                        <tr key={index} className="border-t dark:border-gray-700">
                            <td className="px-4 py-2 dark:text-white">{point.date}</td>
                            <td className="px-4 py-2 dark:text-white">{point.name}</td>
                            <td className="px-4 py-2 dark:text-white">{point.address}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ElementGrid;
