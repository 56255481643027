import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation  } from 'react-router-dom';
import Navbar from './Navbar';
import Explore from './Explore';
import Search from './Search';
import User from './User';
import Create from './Create';
import Register from './Register';
import Login from './Login';
import PasswordResetStart from './PasswordResetStart';
import PasswordResetEnd from './PasswordResetEnd'; // Import the new PasswordResetEnd component
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import Contact from './Contact';
import Docs from './Docs'; // Import the new Docs component
import About from './About'; // Import the new About component
import { ThemeProvider } from './ThemeContext'; // Import the ThemeProvider

const MainLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const location = useLocation();
    const showToolButtons = location.pathname === '/new'; // Show tool buttons only on /new route

    return (
        <div className="flex flex-col h-screen overflow-hidden font-quicksand font-normal">
            <Navbar showToolButtons={showToolButtons} />
            <div className="flex-grow flex">{children}</div>
        </div>
    );
};

// Layout for pages without the Navbar
const SimpleLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <div className="flex flex-col h-screen overflow-hidden font-quicksand font-normal bg-gray-50">
            <div className="flex-grow flex">{children}</div>
        </div>
    );
};

const ScrollableLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <div className="flex flex-col min-h-screen font-quicksand font-normal">
            <div className="flex-grow">{children}</div>
        </div>
    );
};



const App: React.FC = () => {
    return (
        <ThemeProvider> {/* Wrap the app with ThemeProvider */}
            <Router>
                <Routes>
                    {/* Pages that include the Navbar */}
                    <Route path="/" element={<ScrollableLayout><About /></ScrollableLayout>} />
                    <Route path="/search" element={<MainLayout><Search /></MainLayout>} />
                    <Route path="/user" element={<MainLayout><User /></MainLayout>} />
                    <Route path="/new" element={<MainLayout><Create /></MainLayout>} />
                    <Route path="/explore" element={<MainLayout><Explore /></MainLayout>} />
                    <Route path="/docs" element={<MainLayout><Docs /></MainLayout>} />

                    {/* Pages without the Navbar */}
                    <Route path="/sign-in" element={<SimpleLayout><Login /></SimpleLayout>} />
                    <Route path="/sign-up" element={<SimpleLayout><Register /></SimpleLayout>} />

                    {/* Updated Password Reset Routes */}
                    <Route path="/password-reset-request" element={<SimpleLayout><PasswordResetStart /></SimpleLayout>} />
                    <Route path="/password-reset-confirm" element={<SimpleLayout><PasswordResetEnd /></SimpleLayout>} />

                    <Route path="/privacy-policy" element={<SimpleLayout><PrivacyPolicy /></SimpleLayout>} />
                    <Route path="/terms-of-service" element={<SimpleLayout><TermsOfService /></SimpleLayout>} />
                    <Route path="/contact" element={<SimpleLayout><Contact /></SimpleLayout>} />
                </Routes>
            </Router>
        </ThemeProvider>
    );
};

export default App;
