
import React, { useState, useEffect } from 'react';
import Panel from './Panel';
import MapComponent from './MapComponent';
import { Point } from './types';
import ElementGrid from './ElementGrid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons';
const LOCAL_STORAGE_KEY = 'points';

const Create: React.FC = () => {
    const [selectedPoint, setSelectedPoint] = useState({ latitude: '', longitude: '', address: '' });
    const [points, setPoints] = useState<Point[]>([]);
    const [visiblePoints, setVisiblePoints] = useState<Point[]>([]);
    const [timeoutIds, setTimeoutIds] = useState<NodeJS.Timeout[]>([]);
    const [timeProgress, setTimeProgress] = useState(0);
    const [timeProgressIntervalId, setTimeProgressIntervalId] = useState<NodeJS.Timeout | null>(null);
    const [currentVisualizationTime, setCurrentVisualizationTime] = useState(0);
    const [currentRealDate, setCurrentRealDate] = useState<Date | null>(null);
    const [beginningDate, setBeginningDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [isPaused, setIsPaused] = useState(false);
    const [alreadyShownPoints, setAlreadyShownPoints] = useState<number[]>([]);
    const [pauseTime, setPauseTime] = useState<number | null>(null);
    const [activeTab, setActiveTab] = useState<string>('details-tab');
    const [showDropdown, setShowDropdown] = useState(false);
    const [sliderValue, setSliderValue] = useState<number>(120000);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };


    // Function to handle dropdown selection without affecting the slider value in the panel
    const handleDropdownSelection = (time: number) => {
        setShowDropdown(false);  // Close the dropdown
        visualizeTimeMap(time);  // Immediately visualize with the selected time
    };



    const visualizeTimeMap = (visualizationTime: number) => {
        setCurrentVisualizationTime(visualizationTime);
        timeoutIds.forEach(timeout => clearTimeout(timeout));
        if (timeProgressIntervalId) clearInterval(timeProgressIntervalId);
        setTimeoutIds([]);
        setVisiblePoints([]);
        setTimeProgress(0);
        setIsPaused(false);
        setAlreadyShownPoints([]);

        const sortedPointsByStartDate = [...points].sort((a, b) => a.date.getTime() - b.date.getTime());
        const latestEndDate = points.reduce((latest, point) => {
            return point.endDate && point.endDate.getTime() > latest.getTime() ? point.endDate : latest;
        }, sortedPointsByStartDate[sortedPointsByStartDate.length - 1].date);

        setBeginningDate(sortedPointsByStartDate[0].date);
        setEndDate(latestEndDate);

        const totalTimeRange = latestEndDate.getTime() - sortedPointsByStartDate[0].date.getTime();

        sortedPointsByStartDate.forEach((point, index, arr) => {
            const delay = totalTimeRange ? (point.date.getTime() - arr[0].date.getTime()) / totalTimeRange * visualizationTime : 0;

            const timeoutId = setTimeout(() => {
                setVisiblePoints(prev => [...prev, point]);
                setAlreadyShownPoints(prev => [...prev, point.id]);
                const currentDate = new Date(arr[0].date.getTime() + (totalTimeRange * (timeProgress / 100)));
                setCurrentRealDate(currentDate);
            }, delay);
            setTimeoutIds(prev => [...prev, timeoutId]);

            if (point.endDate) {
                const endDelay = totalTimeRange ? (point.endDate.getTime() - arr[0].date.getTime()) / totalTimeRange * visualizationTime : 0;
                const disappearTimeoutId = setTimeout(() => {
                    setVisiblePoints(prev => prev.filter(p => p.id !== point.id));
                }, endDelay);
                setTimeoutIds(prev => [...prev, disappearTimeoutId]);
            }
        });

        const intervalId = setInterval(() => {
            setTimeProgress(prevProgress => {
                if (isPaused) {
                    return prevProgress;
                }
                const increment = 100 / (visualizationTime / 100);
                const nextProgress = prevProgress + increment;
                if (nextProgress >= 100) {
                    clearInterval(intervalId);
                    setTimeProgressIntervalId(null);
                    return 100;
                }
                const currentDate = new Date(sortedPointsByStartDate[0].date.getTime() + (totalTimeRange * (nextProgress / 100)));
                setCurrentRealDate(currentDate);
                return nextProgress;
            });
        }, 100);
        setTimeProgressIntervalId(intervalId);

        const finalTimeout = setTimeout(() => {
            setTimeProgress(100);
            clearInterval(intervalId);
        }, visualizationTime);
        setTimeoutIds(prev => [...prev, finalTimeout]);
    };

    const handleShowPointsClick = () => {
        cancelAllVisualizations();
        setVisiblePoints([...points]);
        setTimeProgress(100);
        setCurrentVisualizationTime(-1);
    };

    const clearSelectedPoint = () => {
        setSelectedPoint({ latitude: '', longitude: '', address: '' });
    };

    const cancelAllVisualizations = () => {
        timeoutIds.forEach(timeout => clearTimeout(timeout));
        if (timeProgressIntervalId) clearInterval(timeProgressIntervalId);
        setTimeoutIds([]);
        setVisiblePoints([]);
        setTimeProgress(0);
        setTimeProgressIntervalId(null);
        setCurrentVisualizationTime(0);
        setIsPaused(false);
        setAlreadyShownPoints([]);
        setPauseTime(null);
    };

    const handlePauseResumeClick = () => {
        const sortedPointsByStartDate = [...points].sort((a, b) => a.date.getTime() - b.date.getTime());
        const totalTimeRange = (endDate ? endDate.getTime() : 0) - (beginningDate ? beginningDate.getTime() : 0);
        const remainingTime = (currentVisualizationTime * (1 - timeProgress / 100));

        if (isPaused) {
            // Resume the visualization
            const nextPoint = sortedPointsByStartDate.find(point => !alreadyShownPoints.includes(point.id));
            if (nextPoint) {
                {/* const now = new Date().getTime(); */}
                const nextPointTime = nextPoint.date.getTime();
                const waitTime = pauseTime ? nextPointTime - pauseTime : 0;

                if (waitTime > 0) {
                    setTimeout(() => {
                        const intervalId = setInterval(() => {
                            setTimeProgress(prevProgress => {
                                const increment = 100 / (remainingTime / 100);
                                const nextProgress = prevProgress + increment;
                                if (nextProgress >= 100) {
                                    clearInterval(intervalId);
                                    setTimeProgressIntervalId(null);
                                    return 100;
                                }
                                const currentDate = new Date(beginningDate!.getTime() + ((endDate!.getTime() - beginningDate!.getTime()) * (nextProgress / 100)));
                                setCurrentRealDate(currentDate);
                                return nextProgress;
                            });
                        }, 100);
                        setTimeProgressIntervalId(intervalId);

                        const remainingPoints = sortedPointsByStartDate.filter(point => !alreadyShownPoints.includes(point.id));
                        const remainingTimeoutIds = remainingPoints.map((point, index, arr) => {
                            const delay = ((point.date.getTime() - arr[0].date.getTime()) / totalTimeRange) * remainingTime;
                            return setTimeout(() => {
                                setVisiblePoints(prev => [...prev, point]);
                                setAlreadyShownPoints(prev => [...prev, point.id]);
                                const currentDate = new Date(arr[0].date.getTime() + (totalTimeRange * (timeProgress / 100)));
                                setCurrentRealDate(currentDate);
                            }, delay);
                        });
                        setTimeoutIds(remainingTimeoutIds);
                    }, waitTime);
                } else {
                    const intervalId = setInterval(() => {
                        setTimeProgress(prevProgress => {
                            const increment = 100 / (remainingTime / 100);
                            const nextProgress = prevProgress + increment;
                            if (nextProgress >= 100) {
                                clearInterval(intervalId);
                                setTimeProgressIntervalId(null);
                                return 100;
                            }
                            const currentDate = new Date(beginningDate!.getTime() + ((endDate!.getTime() - beginningDate!.getTime()) * (nextProgress / 100)));
                            setCurrentRealDate(currentDate);
                            return nextProgress;
                        });
                    }, 100);
                    setTimeProgressIntervalId(intervalId);

                    const remainingPoints = sortedPointsByStartDate.filter(point => !alreadyShownPoints.includes(point.id));
                    const remainingTimeoutIds = remainingPoints.map((point, index, arr) => {
                        const delay = ((point.date.getTime() - arr[0].date.getTime()) / totalTimeRange) * remainingTime;
                        return setTimeout(() => {
                            setVisiblePoints(prev => [...prev, point]);
                            setAlreadyShownPoints(prev => [...prev, point.id]);
                            const currentDate = new Date(arr[0].date.getTime() + (totalTimeRange * (timeProgress / 100)));
                            setCurrentRealDate(currentDate);
                        }, delay);
                    });
                    setTimeoutIds(remainingTimeoutIds);
                }
            }
        } else {
            // Pause the visualization
            timeoutIds.forEach(timeout => clearTimeout(timeout));
            if (timeProgressIntervalId) {
                clearInterval(timeProgressIntervalId);
                setTimeProgressIntervalId(null);
            }
            setPauseTime(new Date().getTime());
        }
        setIsPaused(!isPaused);
    };

    const handleRestartClick = () => {
        visualizeTimeMap(currentVisualizationTime);
    };

    const handleSkipToEndClick = () => {
        setTimeProgress(100);
        setCurrentRealDate(endDate);
        setVisiblePoints(points);
        timeoutIds.forEach(timeout => clearTimeout(timeout));
        if (timeProgressIntervalId) clearInterval(timeProgressIntervalId);
        setTimeoutIds([]);
        setTimeProgressIntervalId(null);
    };

    const handleProgressChange = (progress: number) => {
        const visualizationTime = currentVisualizationTime;
        const newTimeProgress = Math.max(0, Math.min(progress, 100));
        setTimeProgress(newTimeProgress);

        const sortedPointsByStartDate = [...points].sort((a, b) => a.date.getTime() - b.date.getTime());
        const totalTimeRange = (endDate ? endDate.getTime() : 0) - (beginningDate ? beginningDate.getTime() : 0);

        const newVisiblePoints = sortedPointsByStartDate.filter(point => {
            const pointProgress = ((point.date.getTime() - (beginningDate ? beginningDate.getTime() : 0)) / totalTimeRange) * 100;
            return pointProgress <= newTimeProgress;
        });

        setVisiblePoints(newVisiblePoints);

        const newCurrentDate = new Date((beginningDate ? beginningDate.getTime() : 0) + ((totalTimeRange * newTimeProgress) / 100));
        setCurrentRealDate(newCurrentDate);
    };

    useEffect(() => {
        try {
            const savedPoints = localStorage.getItem(LOCAL_STORAGE_KEY);
            if (savedPoints) {
                const parsedPoints: Point[] = JSON.parse(savedPoints);
                setPoints(parsedPoints.map(p => ({ ...p, date: new Date(p.date) })));
            }
        } catch (error) {
            console.error('Failed to load points from local storage:', error);
        }
    }, []);

    useEffect(() => {
        try {
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(points));
        } catch (error) {
            console.error('Failed to save points to local storage:', error);
        }
    }, [points]);

    const timeOptions = [
        { value: 30000, label: '30 sec' },
        { value: 60000, label: '1 min' },
        { value: 90000, label: '1 min 30 sec' },
        { value: 120000, label: '2 min' },
        { value: 150000, label: '2 min 30 sec' },
        { value: 180000, label: '3 min' },
        { value: 300000, label: '5 min' },
        { value: 600000, label: '10 min' },
    ];

    const addPointToList = (name: string, date: Date, endDate: Date | null, description: string) => {
        const dateObj = (typeof date === 'string') ? new Date(date) : date;
        const newPoint = {
            id: points.length + 1,
            name,
            date: dateObj,
            endDate: endDate,
            latitude: selectedPoint.latitude,
            longitude: selectedPoint.longitude,
            address: selectedPoint.address,
            description,
        };
        const newPoints = [...points, newPoint];
        newPoints.sort((a, b) => a.date.getTime() - b.date.getTime());
        setPoints(newPoints);
    };

    return (
        <div className="relative font-inter w-full h-screen font-quicksand font-normal">
            {/* Map Component that takes full screen minus navbar */}
            <div className="absolute inset-x-0 top-[54px] h-[calc(100vh-54px)] z-0">
                <MapComponent
                    onPointSelected={setSelectedPoint}
                    points={points}
                    visiblePoints={visiblePoints}
                    timeProgress={timeProgress}
                    currentVisualizationTime={currentVisualizationTime}
                    beginningDate={beginningDate}
                    currentRealDate={currentRealDate}
                    endDate={endDate}
                    onPauseResumeClick={handlePauseResumeClick}
                    onRestartClick={handleRestartClick}
                    onSkipToEndClick={handleSkipToEndClick}
                    isPaused={isPaused}
                    onProgressChange={handleProgressChange}
                />
            </div>
            {/* Panel and ElementGrid in a vertical stack */}
            <div className="relative mx-6 my-6 top-[54px] z-10 flex flex-col  pointer-events-none">
                {/* Panel */}
                <div
                    className="w-[600px]   py-1  backdrop-filter backdrop-blur-sm bg-white bg-opacity-70 dark:bg-black dark:bg-opacity-70 border border-white border-opacity-30 rounded-lg shadow-xl pointer-events-auto">
                    <Panel
                        selectedPoint={selectedPoint}
                        onAddPoint={() => {}}
                        points={points}
                        onShowPointsClick={() => {}}
                        onVisualizeTimeMap={visualizeTimeMap}
                        onCancelAllVisualizations={() => {}}
                        timeProgress={timeProgress}
                        visiblePointsLength={visiblePoints.length}
                        currentVisualizationTime={currentVisualizationTime}
                        clearSelectedPoint={() => {}}
                        beginningDate={beginningDate}
                        currentRealDate={currentRealDate}
                        endDate={endDate}
                        onPauseResumeClick={() => {}}
                        onRestartClick={() => {}}
                        onSkipToEndClick={() => {}}
                        isPaused={isPaused}
                        setActiveTab={setActiveTab}
                        onDropdownToggle={toggleDropdown}
                        showDropdown={showDropdown}
                    />
                </div>

                {/* Element Grid */}
                {activeTab === 'elements-tab' && (
                    <div className="w-[600px]  p-2 mt-6 backdrop-filter backdrop-blur-sm bg-white bg-opacity-70 dark:bg-black dark:bg-opacity-70 border border-white border-opacity-30 rounded-lg shadow-xl pointer-events-auto">
                        <ElementGrid points={points} />
                    </div>
                )}

                {/* Dropdown menu */}
                {showDropdown && (
                    <div className="w-[246px] p-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 shadow-lg rounded-b-lg">
                        <ul className="text-gray-900 dark:text-white">
                            {timeOptions.map((option) => (
                                <li
                                    key={option.value}
                                    className="cursor-pointer flex rounded-lg items-center px-4 py-1 hover:bg-gray-200 dark:hover:bg-gray-600"
                                    onClick={() => handleDropdownSelection(option.value)}
                                >
                                    <FontAwesomeIcon icon={faCirclePlay} className="mr-2" />
                                    {option.label}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

            </div>
        </div>
    );
};

export default Create;