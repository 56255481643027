import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import axios from 'axios';
import { Point } from './types';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';  // Updated to use faLocationDot
import { HorizontalLayout } from '@hilla/react-components/HorizontalLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStepBackward, faBackward, faPlay, faStop, faForward, faStepForward } from '@fortawesome/free-solid-svg-icons';

interface SelectedPoint {
    latitude: string;
    longitude: string;
    address: string;
}

interface MapComponentProps {
    onPointSelected: (point: SelectedPoint) => void;
    points: Point[];
    visiblePoints: Point[];
    timeProgress: number;
    currentVisualizationTime: number;
    beginningDate: Date | null;
    currentRealDate: Date | null;
    endDate: Date | null;
    onPauseResumeClick: () => void;
    onRestartClick: () => void;
    onSkipToEndClick: () => void;
    isPaused: boolean;
    onProgressChange: (progress: number) => void;
}

// Define a custom icon using an image URL (this should be a local or online image of the FontAwesome icon or a custom marker image)
const customIcon = new L.Icon({
    iconUrl: 'https://example.com/path-to-your-icon.png', // Replace with actual path or URL
    iconSize: [30, 45], // Adjust the size of the icon
    iconAnchor: [15, 45], // Anchor the icon at the bottom center
    popupAnchor: [0, -45], // Adjust the popup position relative to the icon
});

const formatTime = (milliseconds: number): string => {
    const minutes = Math.floor(milliseconds / 60000);
    const seconds = Math.floor((milliseconds % 60000) / 1000);
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};

const MapComponent: React.FC<MapComponentProps> = ({
                                                       onPointSelected,
                                                       points,
                                                       visiblePoints,
                                                       timeProgress,
                                                       currentVisualizationTime,
                                                       beginningDate,
                                                       currentRealDate,
                                                       endDate,
                                                       onPauseResumeClick,
                                                       onRestartClick,
                                                       onSkipToEndClick,
                                                       isPaused,
                                                       onProgressChange,
                                                   }) => {
    const center: L.LatLngExpression = [51.505, -0.09];
    const [markerPosition, setMarkerPosition] = useState<L.LatLng | null>(null);
    const [address, setAddress] = useState<string>('Loading address...');
    const [latestPoint, setLatestPoint] = useState<Point | null>(null);

    const progressBarRef = useRef<HTMLDivElement>(null);
    const firstCloudRef = useRef<HTMLDivElement>(null);
    const [firstCloudHeight, setFirstCloudHeight] = useState<number>(80);

    // Fetch address for the marker's location
    const fetchAddress = async (latlng: L.LatLng) => {
        const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latlng.lat}&lon=${latlng.lng}`);
        let addrString = 'No address found';
        if (response.data?.address) {
            const addr = response.data.address;
            addrString = `${addr.road || ''}, ${addr.city || addr.town || ''}, ${addr.country || ''}`;
        }
        setAddress(addrString);
        onPointSelected({
            latitude: latlng.lat.toFixed(6),
            longitude: latlng.lng.toFixed(6),
            address: addrString,
        });
    };

    // Update marker and fetch address on right-click
    const MapEvents = () => {
        useMapEvents({
            contextmenu: (e) => {
                setMarkerPosition(e.latlng);
                fetchAddress(e.latlng);
            },
        });
        return null;
    };

    useEffect(() => {
        if (visiblePoints.length > 0) {
            setLatestPoint(visiblePoints[visiblePoints.length - 1]);
        }
    }, [visiblePoints]);

    useEffect(() => {
        const handleResize = () => {
            if (firstCloudRef.current) {
                setFirstCloudHeight(firstCloudRef.current.clientHeight);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const passedTime = (currentVisualizationTime * timeProgress) / 100;
    const formattedPassedTime = formatTime(passedTime);
    const formattedTotalTime = formatTime(currentVisualizationTime);

    const handleDrag = (e: any, data: any) => {
        const progressBar = progressBarRef.current;
        if (progressBar) {
            const progress = (data.x / progressBar.clientWidth) * 100;
            onProgressChange(progress);
        }
    };

    const dotPositions = points.map(point => {
        const timeDiff = point.date.getTime() - (beginningDate ? beginningDate.getTime() : 0);
        const progress = (timeDiff / (endDate ? endDate.getTime() - (beginningDate ? beginningDate.getTime() : 0) : 1)) * 100;
        return progress;
    });

    return (
        <div className="relative" style={{ width: '100%', height: 'calc(100vh - 54px)', fontFamily: "'Inter', sans-serif" }}>
            <style>
                {`
                @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700;900&display=swap');
                `}
            </style>
            <MapContainer center={center} zoom={13} zoomControl={false} style={{ height: "100%", width: "100%" }}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <MapEvents />
                {/* Render marker if a position is set */}
                {markerPosition && (
                    <Marker
                        position={markerPosition}
                        icon={customIcon} // Using the custom icon
                    />
                )}
            </MapContainer>

            <div ref={firstCloudRef} className="group absolute bottom-10 inset-x-0  bg-white shadow-lg rounded-2xl p-3 text-center z-[1000] flex items-center justify-center transition-all duration-300 delay-300 ease-in-out mx-auto hover:max-w-[438px] max-w-[330px] hover:max-h-full max-h-[56px] overflow-hidden"
                 style={{ backdropFilter: 'blur(5px)', backgroundColor: 'rgba(255, 255, 255, 0.2)', border: '1px solid rgba(255, 255, 255, 0.3)', borderRadius: '16px', boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)' }}>
                <div>
                    {latestPoint ? (
                        <div
                            className="text-secondary duration-300 delay-300 opacity-0 group-hover:opacity-100 transition-opacity ">
                            <HorizontalLayout className=" duration-300 delay-300 mb-0 group-hover:mb-2.5">
                                <FontAwesomeIcon className="mr-1" icon={faLocationDot}/>
                                {latestPoint.name}
                            </HorizontalLayout>
                        </div>
                    ) : (
                        <div
                            className="text-secondary duration-300 delay-300 opacity-0 group-hover:opacity-100 transition-opacity ">
                            <HorizontalLayout className=" duration-300 delay-300 mb-0 group-hover:mb-2.5">
                                <FontAwesomeIcon className="mr-1" icon={faLocationDot}/>
                                ‎
                            </HorizontalLayout>
                        </div>
                    )}
                    <HorizontalLayout style={{justifyContent: 'space-between', marginTop: '0px'}}>
                        <span
                            className="text-secondary duration-300 delay-300 opacity-0 group-hover:opacity-100 transition-opacity ">{beginningDate ? beginningDate.toLocaleDateString() : '‎'}</span>
                        <span
                            className="text-secondary font-bold">{currentRealDate ? currentRealDate.toLocaleDateString() : '‎'}</span>
                        <span
                            className="text-secondary duration-300 delay-300 opacity-0 group-hover:opacity-100 transition-opacity">{endDate ? endDate.toLocaleDateString() : '‎'}</span>
                    </HorizontalLayout>

                    <div ref={progressBarRef}
                         className="relative duration-300 delay-300 mt-1   group-hover:mt-2 mb-5  group-hover:mb-0 mx-auto  group-hover:w-[400px] w-[300px]"
                         style={{height: '10px', backgroundColor: 'rgba(25, 59, 103, 0.05)', borderRadius: '5px'}}>
                        {dotPositions.map((position, index) => (
                            <div key={index} className="absolute bg-white rounded-full" style={{
                                left: `${position}%`,
                                width: '10px',
                                height: '10px',
                                top: '50%',
                                transform: 'translate(-50%, -50%)',
                                zIndex: 10
                            }}></div>
                        ))}
                        <div style={{
                            width: `${timeProgress}%`,
                            height: '100%',
                            backgroundColor: '#192434',
                            borderRadius: '5px',
                            position: 'relative'
                        }}>
                            <div
                                className="absolute bg-[#192434]  rounded-full duration-300 delay-300 group-hover:w-[16px] group-hover:h-[16px] w-[0px] h-[0px] "
                                style={{right: 0, top: '50%', transform: 'translate(50%, -50%)', zIndex: 12}}></div>
                        </div>
                    </div>

                    <HorizontalLayout className="duration-300 delay-300 ease-in-out group-hover:mb-[0px] mb-[-55px]"
                                      style={{justifyContent: 'space-between', marginTop: '6px'}}>
                        <span
                            className="text-secondary duration-300 delay-300 ease-in-out   opacity-0 group-hover:opacity-100 transition-opacity ">{formattedPassedTime}</span>
                        <span
                            className="text-secondary duration-300 delay-300 ease-in-out  opacity-0 group-hover:opacity-100 transition-opacity ">{formattedTotalTime}</span>
                    </HorizontalLayout>

                    <div style={{justifyContent: 'center', marginTop: '0px', marginBottom: '-4px'}}
                         className="flex space-x-3 group-hover:opacity-100 opacity-0 transition-opacity duration-300 delay-300">
                        <button onClick={onRestartClick}
                                className="rounded-full p-2 bg-gray-800 text-white hover:bg-gray-700">
                            <FontAwesomeIcon icon={faStepBackward}/>
                        </button>
                        <button onClick={() => onProgressChange(timeProgress - 1)}
                                className="rounded-full p-2 bg-gray-800 text-white hover:bg-gray-700 ml-3">
                            <FontAwesomeIcon icon={faBackward}/>
                        </button>
                        <button onClick={onPauseResumeClick}
                                className="rounded-full p-2 bg-gray-800 text-white hover:bg-gray-700 ml-3">
                            <FontAwesomeIcon icon={isPaused ? faPlay : faStop}/>
                        </button>
                        <button onClick={() => onProgressChange(timeProgress + 1)}
                                className="rounded-full p-2 bg-gray-800 text-white hover:bg-gray-700 ml-3">
                            <FontAwesomeIcon icon={faForward}/>
                        </button>
                        <button onClick={onSkipToEndClick}
                                className="rounded-full p-2 bg-gray-800 text-white hover:bg-gray-700 ml-3">
                            <FontAwesomeIcon icon={faStepForward}/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MapComponent;
