import React, { useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompass as faCompassRegular } from '@fortawesome/free-regular-svg-icons';
import { faCompass as faCompassSolid, faCopy, faCirclePlus, faFileImport, faFileExport, faWandMagicSparkles, faCog } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from './ThemeContext';
import { Link } from 'react-router-dom';

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

interface NavbarProps {
    showToolButtons: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ showToolButtons }) => {
    const underlineRef = useRef<HTMLDivElement>(null);
    const location = useLocation();
    const { isDarkMode, toggleDarkMode } = useTheme();

    const isNewPage = location.pathname === '/new';

    return (
        <nav className="fixed font-sans top-0 w-full bg-white dark:bg-[#121212] border-b border-gray-300 dark:border-gray-600 flex items-center h-[54px] z-50 px-5">
            <Link to="/">
                <div className="flex items-center">
                    <img src="/TimeMaps.webp" alt="TM" className="w-10 h-10 mr-2" />
                    <div className="flex items-baseline">
                        <span className="font-semibold text-2xl dark:text-white select-none">time</span>
                        <span className="font-extrabold text-2xl dark:text-white select-none">maps</span>
                        <span className="font-light text-md text-gray-400 select-none">.co</span>
                    </div>
                </div>
            </Link>

            {!isNewPage && (
                <div className="ml-10 flex space-x-2">
                    <NavLink
                        to="/explore"
                        className={({ isActive }) =>
                            classNames(
                                "rounded-lg py-1.5 px-3 flex items-center space-x-2",
                                isActive ? "bg-gray-200 dark:bg-gray-800" : "bg-transparent hover:bg-gray-200 dark:hover:bg-gray-800"
                            )
                        }
                    >
                        {({ isActive }) => (
                            <>
                                <FontAwesomeIcon icon={isActive ? faCompassSolid : faCompassRegular} className="text-black dark:text-white" />
                                <span className="text-black dark:text-white">Explore</span>
                            </>
                        )}
                    </NavLink>

                    <NavLink
                        to="/docs"
                        className={({ isActive }) =>
                            classNames(
                                "rounded-lg py-1.5 px-3 flex items-center space-x-2",
                                isActive ? "bg-gray-200 dark:bg-gray-800" : "bg-transparent hover:bg-gray-200 dark:hover:bg-gray-800"
                            )
                        }
                    >
                        <span className="text-black dark:text-white">Docs</span>
                    </NavLink>

                    <div className="ml-2">
                        <NavLink
                            to="/new"
                            className={({ isActive }) =>
                                classNames(
                                    "rounded-lg py-1.5 px-3 flex items-center space-x-2",
                                    isActive ? "bg-gray-200 dark:bg-gray-800" : "bg-transparent hover:bg-gray-200 dark:hover:bg-gray-800"
                                )
                            }
                        >
                            <FontAwesomeIcon icon={faCirclePlus} className="text-black dark:text-white" />
                            <span className="text-black dark:text-white">Create a time map</span>
                        </NavLink>
                    </div>
                </div>
            )}

            {isNewPage && (
                <div className="ml-10 flex space-x-2">
                    <button
                        className="relative group w-[36px] h-[36px] rounded-lg bg-transparent hover:bg-gray-200 dark:hover:bg-gray-800 active:bg-gray-300 dark:active:bg-gray-700   dark:text-white dark:border-gray-600 flex items-center justify-center">
                        <FontAwesomeIcon icon={faCopy} className="text-lg"/>
                        <span
                            className="absolute left-1/2 transform -translate-x-1/2 top-full mt-1 w-[90px] hidden group-hover:block px-1 py-1 text-xs bg-black text-white rounded-md">
                            Create a copy
                        </span>
                    </button>


                    <button
                        className="relative group w-[36px] h-[36px] rounded-lg bg-transparent hover:bg-gray-200 dark:hover:bg-gray-800 active:bg-gray-300  dark:active:bg-gray-700   dark:text-white dark:border-gray-600 flex items-center justify-center">
                        <FontAwesomeIcon icon={faFileImport} className="text-lg"/>
                        <span
                            className="absolute left-1/2 transform -translate-x-1/2 top-full mt-1 w-[120px] hidden group-hover:block px-1 py-1 text-xs bg-black text-white rounded-md">
                            Import GeoJSON / KML / KMZ
                        </span>
                    </button>

                    <button
                        className="relative group w-[36px] h-[36px] rounded-lg bg-transparent hover:bg-gray-200 dark:hover:bg-gray-800 active:bg-gray-300  dark:active:bg-gray-700  dark:text-white dark:border-gray-600 flex items-center justify-center">
                        <FontAwesomeIcon icon={faFileExport} className="text-lg"/>
                        <span
                            className="absolute left-1/2 transform -translate-x-1/2 top-full mt-1 w-[120px] hidden group-hover:block px-1 py-1 text-xs bg-black text-white rounded-md">
                            Export GeoJSON / KML / KMZ
                        </span>

                    </button>
                    <button
                        className="relative group w-[36px] h-[36px] rounded-lg bg-transparent hover:bg-gray-200 dark:hover:bg-gray-800 active:bg-gray-300   dark:active:bg-gray-700  dark:text-white dark:border-gray-600 flex items-center justify-center">
                        <FontAwesomeIcon icon={faWandMagicSparkles} className="text-lg"/>
                        <span
                            className="absolute left-1/2 transform -translate-x-1/2 top-full mt-1 w-[114px] hidden group-hover:block px-1 py-1 text-xs bg-black text-white rounded-md">
                            Use AI to turn text into elements
                        </span>
                    </button>
                    <button
                        className="relative group w-[36px] h-[36px] rounded-lg bg-transparent hover:bg-gray-200 dark:hover:bg-gray-800 active:bg-gray-300  dark:active:bg-gray-700   dark:text-white dark:border-gray-600 flex items-center justify-center">
                        <FontAwesomeIcon icon={faCog} className="text-lg"/>
                        <span
                            className="absolute left-1/2 transform -translate-x-1/2 top-full mt-1 w-[90px] hidden group-hover:block px-1 py-1 text-xs bg-black text-white rounded-md">
                            Preferences
                        </span>
                    </button>
                </div>
            )}

            <div className="ml-auto flex space-x-2 items-center">
                {isNewPage && (
                    <>
                        <NavLink
                            to="/explore"
                            className={({isActive}) =>
                                classNames(
                                    "rounded-lg py-1.5 px-3 flex items-center space-x-2",
                                    isActive ? "bg-gray-200 dark:bg-gray-800" : "bg-transparent hover:bg-gray-200 dark:hover:bg-gray-800"
                                )
                            }
                        >
                            {({isActive }) => (
                                <>
                                    <FontAwesomeIcon icon={isActive ? faCompassSolid : faCompassRegular} className="text-black dark:text-white" />
                                    <span className="text-black dark:text-white">Explore</span>
                                </>
                            )}
                        </NavLink>

                        <NavLink
                            to="/docs"
                            className={({ isActive }) =>
                                classNames(
                                    "rounded-lg py-1.5 px-3 flex items-center space-x-2",
                                    isActive ? "bg-gray-200 dark:bg-gray-800" : "bg-transparent hover:bg-gray-200 dark:hover:bg-gray-800"
                                )
                            }
                        >
                            <span className="text-black dark:text-white">Docs</span>
                        </NavLink>
                    </>
                )}

                <NavLink to="/sign-in" className="rounded-lg bg-transparent hover:bg-gray-200 dark:hover:bg-gray-800 py-1.5 px-3">
                    <span className="text-black dark:text-white">Sign in</span>
                </NavLink>

                <NavLink to="/sign-up" className="rounded-lg bg-[#23b723] hover:bg-[#7cd47d] py-1.5 px-3">
                    <span className="text-white dark:text-white">Sign up</span>
                </NavLink>

                <button
                    type="button"
                    onClick={toggleDarkMode}
                    aria-label="Toggle Dark Mode"
                    className="rounded-lg bg-transparent hover:bg-gray-200 dark:hover:bg-gray-800 w-[36px] h-[36px] flex items-center justify-center"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                        className="block dark:hidden text-black dark:text-white"
                        height="20"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z"
                        />
                    </svg>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                        className="hidden dark:block text-black dark:text-white"
                        height="20"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
                        />
                    </svg>
                </button>
            </div>
        </nav>
    );
};

export default Navbar;
